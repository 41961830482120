import React from 'react'

function News() {
    return (
        <>
            <div class="container my-24 mx-auto md:px-6">
                <section class="mb-32 text-center">
                    <div class="mb-12 flex flex-wrap justify-center">
                        <div class="w-full shrink-0 grow-0 basis-auto px-3 md:w-10/12">
                            <div class="relative mb-6 overflow-hidden rounded-lg bg-cover bg-no-repeat shadow-lg dark:shadow-black/20"
                                data-te-ripple-init data-te-ripple-color="light">
                                <img src="https://mdbcdn.b-cdn.net/img/new/slides/080.jpg" class="w-full" />
                                <a href="#!">
                                    <div
                                        class="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100 bg-[hsla(0,0%,98.4%,.15)]">
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div class="w-full shrink-0 grow-0 basis-auto px-3 md:w-8/12 xl:w-6/12">
                            <h5 class="mb-3 text-lg font-bold">
                                This is a very long post title
                            </h5>
                            <p class="mb-4 text-neutral-500 dark:text-neutral-300">
                                <small>Published <u>15.01.2022</u> by
                                    <a href="#!">Anna Maria Doe</a></small>
                            </p>
                            <p class="mb-6">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa
                                atque hic, officiis blanditiis accusantium veritatis ullam?
                                Maiores atque autem velit officiis molestiae voluptates suscipit
                                eligendi, vero expedita sequi, doloremque modi?
                            </p>
                            <a data-te-ripple-init data-te-ripple-color="light"
                                class="inline-block rounded-full bg-primary px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                                href="" role="button">Read more</a>
                        </div>
                    </div>

                    <div class="mb-12 flex flex-wrap justify-center">
                        <div class="w-full shrink-0 grow-0 basis-auto px-3 md:w-10/12">
                            <div class="relative mb-6 overflow-hidden rounded-lg bg-cover bg-no-repeat shadow-lg dark:shadow-black/20"
                                data-te-ripple-init data-te-ripple-color="light">
                                <img src="https://mdbcdn.b-cdn.net/img/new/slides/100.jpg" class="w-full" />
                                <a href="#!">
                                    <div
                                        class="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100 bg-[hsla(0,0%,98.4%,.15)]">
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div class="w-full shrink-0 grow-0 basis-auto px-3 md:w-8/12 xl:w-6/12">
                            <h5 class="mb-3 text-lg font-bold">
                                This is a very long post title
                            </h5>

                            <p class="mb-4 text-neutral-500 dark:text-neutral-300">
                                <small>Published <u>14.01.2022</u> by
                                    <a href="#!">Anna Maria Doe</a></small>
                            </p>
                            <p class="mb-6">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa
                                atque hic, officiis blanditiis accusantium veritatis ullam?
                                Maiores atque autem velit officiis molestiae voluptates suscipit
                                eligendi, vero expedita sequi, doloremque modi?
                            </p>
                            <a data-te-ripple-init data-te-ripple-color="light"
                                class="inline-block rounded-full bg-primary px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                                href="" role="button">Read more</a>
                        </div>
                    </div>

                    <div class="mb-12 flex flex-wrap justify-center">
                        <div class="w-full shrink-0 grow-0 basis-auto px-3 md:w-10/12">
                            <div class="relative mb-6 overflow-hidden rounded-lg bg-cover bg-no-repeat shadow-lg dark:shadow-black/20"
                                data-te-ripple-init data-te-ripple-color="light">
                                <img src="https://mdbcdn.b-cdn.net/img/new/slides/101.jpg" class="w-full" />
                                <a href="#!">
                                    <div
                                        class="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100 bg-[hsla(0,0%,98.4%,.15)]">
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div class="w-full shrink-0 grow-0 basis-auto px-3 md:w-8/12 xl:w-6/12">
                            <h5 class="mb-3 text-lg font-bold">
                                This is a very long post title
                            </h5>

                            <p class="mb-4 text-neutral-500 dark:text-neutral-300">
                                <small>Published <u>13.01.2022</u> by
                                    <a href="#!">Anna Maria Doe</a></small>
                            </p>
                            <p class="mb-6">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa
                                atque hic, officiis blanditiis accusantium veritatis ullam?
                                Maiores atque autem velit officiis molestiae voluptates suscipit
                                eligendi, vero expedita sequi, doloremque modi?
                            </p>
                            <a data-te-ripple-init data-te-ripple-color="light"
                                class="inline-block rounded-full bg-primary px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                                href="" role="button">Read more</a>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default News