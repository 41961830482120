import React, { useEffect, useState, useRef } from "react";
import { Card, CardBody, Typography } from "@material-tailwind/react";
import { motion, useAnimation } from "framer-motion";

const stats = [
  { id: 1, name: "Varieties of Ayurvedic Products", value: "200+" },
  { id: 2, name: "Average Customer Rating", value: "4.8+" },
  { id: 3, name: "Certified Ayurvedic Practitioners", value: "50+" },
  { id: 4, name: "Daily Orders Processed", value: "500+" },
  { id: 5, name: "Years in Business", value: "15+" },
  { id: 6, name: "Delivery", value: "Nationwide" },
];

function Stats() {
  const [currentCounts, setCurrentCounts] = useState(stats.map(() => 0));
  const controls = useAnimation();
  const sectionRef = useRef(null);

  useEffect(() => {
    const intervals = stats.map((stat, index) => {
      return setInterval(() => {
        setCurrentCounts((prevCounts) => {
          const newCounts = [...prevCounts];
          if (newCounts[index] < stat.value) {
            newCounts[index] += Math.ceil(stat.value / 100);
          } else {
            newCounts[index] = stat.value;
            clearInterval(intervals[index]);
          }
          return newCounts;
        });
      }, 30);
    });

    return () => intervals.forEach(clearInterval);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          controls.start("visible");
        } else {
          controls.start("hidden");
        }
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, [controls]);

  return (
    <div ref={sectionRef} className="bg-gray-50 py-12">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <motion.div
            initial="hidden"
            animate={controls}
            variants={{
              hidden: { opacity: 0, y: -20 },
              visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
            }}
          >
            <Typography variant="h2" color="green" className="font-extrabold">
              Your Wellness, Our Priority
            </Typography>
            <Typography variant="body1" color="gray" className="mt-4">
              We are dedicated to providing the highest quality Ayurvedic
              products tailored to your wellness needs. With a commitment to
              natural healing and customer satisfaction, our platform ensures a
              holistic experience every time you choose our products.
            </Typography>
          </motion.div>
        </div>
        <div className="flex flex-wrap justify-center">
          {stats.map((stat, index) => (
            <motion.div
              key={stat.id}
              initial="hidden"
              animate={controls}
              variants={{
                hidden: { opacity: 0, y: 50 },
                visible: {
                  opacity: 1,
                  y: 0,
                  transition: { duration: 0.5, delay: index * 0.2 },
                },
              }}
              className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 m-4 flex"
            >
              <Card className="flex flex-col flex-grow">
                <CardBody className="text-center flex flex-col flex-grow">
                  <motion.div
                    initial="hidden"
                    animate={controls}
                    variants={{
                      hidden: { opacity: 0 },
                      visible: {
                        opacity: 1,
                        transition: { duration: 0.5, delay: index * 0.3 },
                      },
                    }}
                  >
                    <Typography
                      variant="h3"
                      color="green"
                      className="font-extrabold mt-4"
                    >
                      {typeof stat.value === "number"
                        ? currentCounts[index]
                        : stat.value}
                    </Typography>
                    <Typography variant="body2" color="gray" className="mt-2">
                      {stat.name}
                    </Typography>
                  </motion.div>
                </CardBody>
              </Card>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Stats;
