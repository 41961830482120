import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import FrontPageForm from "./features/FrontPage/FrontPageForm";
import Registration from "./features/RegistrationForm/Registration";
import UserProfile from "./features/UserProfile/UserProfile";
import EmailVerify from "./features/EmailVerify/EmailVerify";
import ForgotPass from "./features/ForgotPassword/ForgotPass";
import EmailConfirm from "./features/ForgotPassword/EmailConfirm";
import UpdateProfile from "./features/UserProfile/UpdateProfile";
import PasswordReset from "./features/UserProfile/PasswordReset";
import EditProfile from "./features/UserProfile/EditProfile";
import Login from "./features/LoginForm/Login";
import NavbarForm from "./features/NavbarForm/NavbarForm";
import Stats from "./features/FrontPage/Stats";
import Testimonials from "./features/FrontPage/Testimonials";
import Footer from "./features/FrontPage/Footer";
import FrontPageProduct from "./features/FrontPage/FrontPageProduct";
import DiscountProduct from "./features/FrontPage/DiscountProduct";
import OtherProducts from "./features/Product/HealthWellnessSupplements";
import MilkProducts from "./features/Product/HerbalPersonalCare";
import MilkType from "./features/Product/AyurvedicRemedies";
import QuickView from "./features/FrontPage/Quickview";
import Cart from "./features/Cart/Cart";
import News from "./features/News/News";
import ContactUs from "./features/Contact/ContactUs";
import Blog from "./features/Blog/Blog";
import Checkout from "./features/Checkout/Checkout";
import FAQs from "./features/FooterLinks/FAQs/FAQs";
import ProductOverview from "./features/Product/ProductOverview";
import AboutUs from "./features/About/AboutUs";
import Support from "./features/Support/Support";
import OrderHistory from "./features/OrderHistory/OrderHistory";
import OrderSummary from "./features/OrderSummary/OrderSummary";
import { useSelector } from "react-redux";
import PageNotFound from "./features/PageNotFound/PageNotFound";
import LoginRequired from "./features/PageNotFound/LoginRequired";
import Wishlist from "./features/Cart/Wishlist";
import OrderDetails from "./features/OrderDetails/OrderDetails";
import Invoice from "./features/OrderSummary/Invoice";
import Payment from "./features/Payment/Payment";
import AdminProducts from "./features/AdminPanel/Products/AdminProducts";
import AdminAddProducts from "./features/AdminPanel/Products/AdminAddProducts";
import AdminCategories from "./features/AdminPanel/Categories/AdminCategories";
import AdminAddCategories from "./features/AdminPanel/Categories/AdminAddCategories";
import AdminOrders from "./features/AdminPanel/Orders/AdminOrders";
import AdminAddOrders from "./features/AdminPanel/Orders/AdminAddOrders";
import AdminEditProducts from "./features/AdminPanel/Products/AdminEditProducts";
import AdminUsers from "./features/AdminPanel/Users/AdminUsers";
import AdminAddUsers from "./features/AdminPanel/Users/AdminAddUsers";
import FaceSkinCare from "./features/Product/FaceSkinCare";
import HairCare from "./features/Product/HairCare";
import SoapsGels from "./features/Product/SoapsGels";
import AyurvedicRemedies from "./features/Product/AyurvedicRemedies";
import HerbalPersonalCare from "./features/Product/HerbalPersonalCare";
import HealthWellnessSupplements from "./features/Product/HealthWellnessSupplements";
import OurFeatures from "./features/FrontPage/OurFeatures";

const App = () => {
  const loggedIn = useSelector((state) => state?.userDetails?.login);

  // useEffect(() => {
  //   fetch("/data.json") // Adjust the file path here
  //     .then((response) => {
  //       console.log("Response:", response);
  //       if (!response.ok) {
  //         throw new Error(`Error: ${response.status} ${response.statusText}`);
  //       }
  //       return response.json();
  //     })
  //     .then((data) => {
  //       // Send data to backend
  //       fetch("http://localhost:5001/api/sync-products", {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({ products: data }), // Correct structure if needed
  //       })
  //         .then((response) => {
  //           if (!response.ok) {
  //             throw new Error(
  //               `Error: ${response.status} ${response.statusText}`
  //             );
  //           }
  //           return response.json();
  //         })
  //         .then((result) => {
  //           console.log("Data synchronized:", result);
  //         })
  //         .catch((error) => {
  //           console.error("Error synchronizing data:", error);
  //         });
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching data:", error);
  //     });
  // }, []);

  return (
    <Router>
      <NavbarForm />
      <Routes>
        {/* <Route path='*' element={<PageNotFound />} /> */}
        <Route path="/login" element={<Login />} />
        <Route path="/registration" element={<Registration />} />
        <Route path="/ayurvedic-remedies" element={<AyurvedicRemedies />} />
        <Route path="/herbal-personal-care" element={<HerbalPersonalCare />} />
        <Route
          path="/health-wellness-supplements"
          element={<HealthWellnessSupplements />}
        />
        <Route path="/face-skin-care" element={<FaceSkinCare />} />
        <Route path="/hair-care" element={<HairCare />} />
        <Route path="/soaps-gels" element={<SoapsGels />} />

        <Route path="/support" element={<Support />} />
        <Route path="quickview" element={<QuickView />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="news" element={<News />} />
        <Route path="contact" element={<ContactUs />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/faq" element={<FAQs />} />
        <Route
          path="/productOverview/:productId"
          element={<ProductOverview />}
        />
        <Route path="about" element={<AboutUs />} />
        <Route path="/emailverify" element={<EmailVerify />} />
        <Route path="/emailConfirm" element={<EmailConfirm />} />
        <Route path="/forgotPass" element={<ForgotPass />} />
        <Route
          path="/"
          element={
            <>
              <FrontPageForm />
              <FrontPageProduct />
              {/* <ProductList /> */}
              {/* <DiscountProduct /> */}

              <Stats />
              <OurFeatures />
              <Testimonials />
              <Footer />
            </>
          }
        />
        {loggedIn ? (
          <>
            <Route path="/editprofile" element={<EditProfile />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/userprofile" element={<UserProfile />} />
            <Route path="orderDetails" element={<OrderDetails />} />
            <Route path="/updateProfile" element={<UpdateProfile />} />
            <Route path="/changePass" element={<PasswordReset />} />
            <Route path="/orderHistory" element={<OrderHistory />} />
            <Route path="orderSummary" element={<OrderSummary />} />
            <Route path="/wishlist" element={<Wishlist />} />
            <Route path="/invoice" element={<Invoice />} />
            <Route path="payment" element={<Payment />} />
            <Route path="/admin-products" element={<AdminProducts />} />
            <Route path="/admin-add-products" element={<AdminAddProducts />} />
            <Route
              path="/admin-edit-products/:id"
              element={<AdminEditProducts />}
            />
            <Route path="/admin-categories" element={<AdminCategories />} />
            <Route
              path="/admin-add-categories"
              element={<AdminAddCategories />}
            />
            <Route path="/admin-orders" element={<AdminOrders />} />
            <Route path="/admin-add-orders" element={<AdminAddOrders />} />
            <Route path="/admin-users" element={<AdminUsers />} />
            <Route path="/admin-add-users" element={<AdminAddUsers />} />
            <Route path="/admin-edit-users" element={<AdminEditProducts />} />
          </>
        ) : (
          <Route path="*" element={<LoginRequired />} />
        )}
      </Routes>
    </Router>
  );
};

export default App;
