// import team2 from "../../assets/images/about/team2.jpg";
// import team3 from "../../assets/images/about/team3.jpg";
// import team4 from "../../assets/images/about/team4.jpg";
// import team5 from "../../assets/images/about/team5.jpg";
// import team6 from "../../assets/images/about/team6.jpg";

// const people = [
//   {
//     name: "Leslie Alexander",
//     role: "Co-Founder / CEO",
//     imageUrl:
//       "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
//   },
//   {
//     name: "Michael Foster",
//     role: "Co-Founder / CTO",
//     imageUrl: team2,
//   },
//   {
//     name: "Dries Vincent",
//     role: "Business Relations",
//     imageUrl: team3,
//   },
//   {
//     name: "Lindsay Walton",
//     role: "Front end Developer",
//     imageUrl: team4,
//   },
//   {
//     name: "Courtney Henry",
//     role: "Designer",
//     imageUrl: team5,
//   },
//   {
//     name: "Tom Cook",
//     role: "Director of Product",
//     imageUrl: team6,
//   },
// ];

// export default function AboutUs() {
//   return (
//     <div className="bg-white py-24 sm:py-12">
//       <div className="mx-auto grid max-w-7xl gap-x-8 px-6 lg:px-8">
//         <h2 className="mt-6 text-3xl font-extrabold text-gray-900 sm:text-4xl">
//           Hii!!! Greetings from the UPCHAR HERBAL & AYURVEDA PHARMACEUTICALS
//         </h2>
//         <p className="mt-6 text-md text-gray-500 text-justify">
//           Our all products of Ayurvedic Products and Herbal Cosmetics.
//         </p>
//         <p className="mt-6 text-md text-gray-500 text-justify">
//           We will deal in PCD Products. We have a wide range of products in PCD
//           125+ products. In Ayurvedic Products we have sections like
//           Syrups,Capsules,Oils,Ras,Powders,Churans, Shilajeet Raisin etc. In
//           Herbal Cosmetics we have sections like
//           Facewashs,Shampoos,Lotions,Soaps,Creams,Scrubs,Gel etc. Our All
//           Products are ISO and GMP Certified. All Preparations of our Syrups are
//           specially Quath Base. I assured to provide best quality of Products
//           and timely delivery to our clients
//         </p>
//         <p className="mt-6 text-md text-gray-500 text-justify">
//           Why Choose Dairy Delight?
//         </p>
//         <ul className="mt-3 list-disc list-inside text-md text-gray-500 text-justify">
//           <li>
//             **Unmatched Quality:** Experience the epitome of dairy excellence
//             with our handpicked selection of the finest products. We prioritize
//             quality above all else to bring you a taste that transcends
//             expectations.
//           </li>
//           <li>
//             **Tailored to You:** No two preferences are the same, and neither
//             should your dairy choices be. Personalize your experience and
//             receive exactly what you desire, every single time.
//           </li>
//           <li>
//             **Reliability You Can Trust:** Our commitment to consistency ensures
//             that you can rely on us for a delightful experience, whether it's
//             your daily dairy fix or a special treat.
//           </li>
//           <li>
//             **Innovative Statistics:** Explore the fascinating world of dairy
//             with our insightful statistics. From nutritional information to the
//             journey of our products, stay informed and make choices that align
//             with your lifestyle.
//           </li>
//         </ul>
//         <p className="mt-6 text-md text-gray-500 text-justify">
//           **Delve into the World of Dairy with Dairy Delight.** Indulge in a
//           symphony of flavors, discover the story behind each product, and
//           elevate your culinary experience with the assurance that Dairy Delight
//           is here to deliver what you want, every time.
//         </p>
//       </div>
//       <div className="mx-auto grid max-w-7xl gap-x-8 gap-y-20 px-6 lg:px-8 xl:grid-cols-3 mt-10 pt-10">
//         <div className="max-w-2xl">
//           <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
//             Meet our leadership
//           </h2>
//           <p className="mt-6 text-lg leading-8 text-gray-600 text-justify">
//             We’re a dynamic group of individuals who are passionate about what
//             we do and dedicated to delivering the best results for our clients..
//           </p>
//         </div>
//         <ul
//           role="list"
//           className="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2"
//         >
//           {people.map((person) => (
//             <li key={person.name}>
//               <div className="flex items-center gap-x-6">
//                 <img
//                   className="h-16 w-16 rounded-full"
//                   src={person.imageUrl}
//                   alt=""
//                 />
//                 <div>
//                   <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
//                     {person.name}
//                   </h3>
//                   <p className="text-sm font-semibold leading-6 text-indigo-600">
//                     {person.role}
//                   </p>
//                 </div>
//               </div>
//             </li>
//           ))}
//         </ul>
//       </div>
//     </div>
//   );
// }

// import React from "react";

// const AboutUs = () => {
//   return (
//     <div className="p-8 bg-gradient-to-r from-blue-50 to-green-50 text-gray-800 min-h-screen">
//       <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg p-8">
//         <h1 className="text-4xl font-bold text-green-700 mb-4 text-center">
//           UPCHAR HERBAL & AYURVEDA PHARMACEUTICALS
//         </h1>
//         <p className="mb-6 text-lg text-center">
//           Welcome to UPCHAR Herbal & Ayurveda Pharmaceuticals, where we believe
//           in the power of nature to heal and rejuvenate. Our mission is to
//           provide high-quality, natural products that promote health and
//           well-being.
//         </p>
//         <section className="mb-6">
//           <h2 className="text-2xl font-semibold text-green-600 mb-2">
//             Our Mission
//           </h2>
//           <p>
//             To harness the ancient wisdom of Ayurveda and modern scientific
//             research to create effective and safe herbal products.
//           </p>
//         </section>
//         <section className="mb-6">
//           <h2 className="text-2xl font-semibold text-green-600 mb-2">
//             Our Products
//           </h2>
//           <p>
//             We offer a wide range of herbal supplements, skincare products, and
//             wellness solutions designed to support your health naturally.
//           </p>
//           <ul className="list-disc list-inside ml-4">
//             <li>
//               Our Ayurvedic Products include sections like Syrups, Capsules,
//               Oils, Ras, Powders, Churans, Shilajeet Raisin, etc.
//             </li>
//             <li>
//               In Herbal Cosmetics, we have sections like Facewashes, Shampoos,
//               Lotions, Soaps, Creams, Scrubs, Gel, etc.
//             </li>
//             <li>
//               We deal in PCD Products and have a wide range of 125+ products in
//               PCD.
//             </li>
//             <li>
//               All our products are ISO and GMP Certified. All preparations of
//               our Syrups are specially Quath Base.
//             </li>
//             <li>
//               We assure to provide the best quality of products and timely
//               delivery to our clients.
//             </li>
//           </ul>
//         </section>
//         <section>
//           <h2 className="text-2xl font-semibold text-green-600 mb-2">
//             Contact Us
//           </h2>
//           <p>
//             Have questions? Reach out to us at{" "}
//             <a
//               href="mailto:info@herbalayurveda.com"
//               className="text-green-700 underline"
//             >
//               info@herbalayurveda.com
//             </a>
//             .
//           </p>
//         </section>
//       </div>
//     </div>
//   );
// };

// export default AboutUs;

import React from "react";

const AboutUs = () => {
  return (
    <div className="p-8 bg-gray-50 text-gray-800 min-h-screen flex items-center justify-center">
      <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg p-8">
        <h1 className="text-4xl font-bold text-green-700 mb-4 text-center">
          UPCHAR HERBAL & AYURVEDA PHARMACEUTICALS
        </h1>
        <p className="mb-6 text-lg text-center">
          Welcome to UPCHAR Herbal & Ayurveda Pharmaceuticals, where we believe
          in the power of nature to heal and rejuvenate. Our mission is to
          provide high-quality, natural products that promote health and
          well-being.
        </p>
        <section className="mb-6">
          <h2 className="text-2xl font-semibold text-green-600 mb-2">
            Our Mission
          </h2>
          <p>
            To harness the ancient wisdom of Ayurveda and modern scientific
            research to create effective and safe herbal products.
          </p>
        </section>
        <section className="mb-6">
          <h2 className="text-2xl font-semibold text-green-600 mb-2">
            Our Products
          </h2>
          <p>
            We offer a wide range of herbal supplements, skincare products, and
            wellness solutions designed to support your health naturally.
          </p>
          <ul className="list-disc list-inside ml-4">
            <li>
              Our Ayurvedic Products include sections like Syrups, Capsules,
              Oils, Ras, Powders, Churans, Shilajeet Raisin, etc.
            </li>
            <li>
              In Herbal Cosmetics, we have sections like Facewashes, Shampoos,
              Lotions, Soaps, Creams, Scrubs, Gel, etc.
            </li>
            <li>
              We deal in PCD Products and have a wide range of 125+ products in
              PCD.
            </li>
            <li>
              All our products are ISO and GMP Certified. All preparations of
              our Syrups are specially Quath Base.
            </li>
            <li>
              We assure to provide the best quality of products and timely
              delivery to our clients.
            </li>
          </ul>
        </section>
        <section>
          <h2 className="text-2xl font-semibold text-green-600 mb-2">
            Contact Us
          </h2>
          <p>
            Have questions? Reach out to us at{" "}
            <a
              href="mailto:info@herbalayurveda.com"
              className="text-green-700 underline"
            >
              info@herbalayurveda.com
            </a>
            .
          </p>
        </section>
      </div>
    </div>
  );
};

export default AboutUs;
