// import React from 'react';
// import axios from 'axios';
// import { Link, useNavigate } from 'react-router-dom';

// const Registration = () => {

//     const navigate = useNavigate();

//     function handleSubmit(e) {
//         e.preventDefault();
//         const formData = new FormData(e.target);
//         const data = Object.fromEntries(formData.entries());
//         console.log("51561", data)
//         axios.post("http://localhost:5001/auth/register", data)
//             .then(res => {
//                 if (res.data.success) {
//                     alert("Your data has been submitted successfully.");
//                     navigate('/login');
//                 }
//             })
//             .catch(err => {
//                 if (err.response && err.response.data && err.response.data.message) {
//                     alert(err.response.data.message);
//                 } else {
//                     console.error(err);
//                     alert("An error occurred during registration.");
//                 }
//             });
//     }

//     return (
//         <>
//             <div className='flex justify-center items-center pt-14 overflow-hidden'>
//                 <form class="max-w-full mx-auto" onSubmit={handleSubmit}>
//                     <div class="relative z-0 w-full mb-5 group">
//                         <input
//                             type="email"
//                             name="email"
//                             id="floating_email"
//                             class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
//                             placeholder=" "
//                             required
//                         />
//                         <label
//                             htmlFor="email"
//                             class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
//                         >
//                             Email address
//                         </label>
//                     </div>
//                     <div class="relative z-0 w-full mb-5 group">
//                         <input
//                             type="password"
//                             name="password"
//                             id="floating_password"
//                             class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
//                             placeholder=" "
//                             required
//                         />
//                         <label
//                             htmlFor="password"
//                             class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
//                         >
//                             Password
//                         </label>
//                     </div>
//                     <div class="relative z-0 w-full mb-5 group">
//                         <input
//                             type="password"
//                             name="confirmPwd"
//                             id="floating_repeat_password"
//                             class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
//                             placeholder=" "
//                             required
//                         />
//                         <label
//                             htmlFor="confirmPwd"
//                             class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
//                         >
//                             Confirm password
//                         </label>
//                     </div>
//                     <div class="grid md:grid-cols-2 md:gap-6">
//                         <div class="relative z-0 w-full mb-5 group">
//                             <input
//                                 type="text"
//                                 name="fname"
//                                 id="floating_first_name"
//                                 class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
//                                 placeholder=" "
//                                 required
//                             />
//                             <label
//                                 htmlFor="fname"
//                                 class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
//                             >
//                                 Full name
//                             </label>
//                         </div>
//                         <div class="relative z-0 w-full mb-5 group">
//                             <input
//                                 type="tel"
//                                 name="phone"
//                                 pattern='[0-9]{10}'
//                                 id="floating_last_name"
//                                 class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
//                                 placeholder=" "
//                                 required
//                             />
//                             <label
//                                 for="phone"
//                                 class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
//                             >
//                                 Phone Number
//                             </label>
//                         </div>
//                     </div>
//                     <div className='flex flex-col items-center justify-between sm:flex-row sm:items-center mt-5'>
//                         <p className="mb-2 sm:mb-0 sm:mr-3">
//                             Existing User?
//                             <Link
//                                 to={'/login'}
//                                 className='ml-3 font-bold rounded-md hover:bg-gray-100 text-blue-600'
//                             >
//                                 Log in
//                                 <span aria-hidden="true"> &rarr;</span>
//                             </Link>
//                         </p>
//                         <button
//                             type="submit"
//                             className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
//                         >
//                             Submit
//                         </button>
//                     </div>
//                 </form>
//             </div>
//         </>
//     );
// }

// export default Registration

import React, { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Input, Button, Typography, Card } from "@material-tailwind/react";

const Registration = () => {
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  const [values, setValues] = useState({
    email: "",
    password: "",
    confirmPwd: "",
    fname: "",
    phone: "",
  });

  const handleInput = (event) => {
    setValues((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  // function handleSubmit(e) {
  //   e.preventDefault();
  //   console.log("Form submitted with values:", values);
  //   axios
  //     .post(`${apiUrl}/auth/register`, values)
  //     .then((res) => {
  //       console.log("Response:", res);
  //       if (res.data.success) {
  //         alert("Your data has been submitted successfully.");
  //         navigate("/login");
  //       }
  //     })
  //     .catch((err) => {
  //       console.error("Error:", err);
  //       if (err.response && err.response.data && err.response.data.message) {
  //         alert(err.response.data.message);
  //       } else {
  //         console.error(err);
  //         alert("An error occurred during registration.");
  //       }
  //     });
  // }

  function handleSubmit(e) {
    e.preventDefault();
    axios
      .post(`${apiUrl}/auth/register`, values)
      .then((res) => {
        if (res.data.success) {
          alert("Your data has been submitted successfully.");
          navigate("/login");
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.data && err.response.data.message) {
            alert(err.response.data.message);
          } else {
            alert("An error occurred during registration.");
          }
        } else {
          alert("An error occurred during registration.");
        }
      });
  }

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-gray-100 overflow-hidden">
      <Card className="w-full max-w-md p-6">
        <Typography variant="h4" color="blue-gray" className="mb-6">
          Registration
        </Typography>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <Input
              type="email"
              name="email"
              label="Email"
              size="lg"
              required
              onChange={handleInput}
              className="peer focus:outline-none focus:ring-0"
            />
          </div>
          <div className="mb-4">
            <Input
              type="password"
              name="password"
              label="Password"
              size="lg"
              required
              onChange={handleInput}
              className="peer focus:outline-none focus:ring-0"
            />
          </div>
          <div className="mb-4">
            <Input
              type="password"
              name="confirmPwd"
              label="Confirm Password"
              size="lg"
              required
              onChange={handleInput}
              className="peer focus:outline-none focus:ring-0"
            />
          </div>
          <div className="mb-4">
            <Input
              type="text"
              name="fname"
              label="Full Name"
              size="lg"
              required
              onChange={handleInput}
              className="peer focus:outline-none focus:ring-0"
            />
          </div>
          <div className="mb-6">
            <Input
              type="tel"
              name="phone"
              pattern="[0-9]{10}"
              label="Phone Number"
              size="lg"
              required
              onChange={handleInput}
              className="peer focus:outline-none focus:ring-0"
            />
          </div>
          <div className="flex justify-between items-center mb-4">
            <Typography variant="small" className="text-center">
              Existing User?
              <Link
                to={"/login"}
                className="ml-1 text-blue-600 hover:underline"
              >
                Log in &rarr;
              </Link>
            </Typography>
            <Button type="submit" color="blue" size="lg">
              Submit
            </Button>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default Registration;
