import { Carousel } from "@material-tailwind/react";
import React from "react";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { RiDoubleQuotesL, RiDoubleQuotesR } from "react-icons/ri";

const testimonials = [
  {
    description:
      "Our customers love our Ayurvedic products and the positive impact they have on their health.",
    reviews: [
      {
        name: "Rahul Sharma",
        role: "Health Enthusiast",
        image: "https://tecdn.b-cdn.net/img/Photos/Avatars/img%20(9).jpg",
        text: "I've been using these Ayurvedic products for a few months now, and I can already feel a significant improvement in my overall well-being.",
        rating: 5,
      },
      {
        name: "Priya Singh",
        role: "Yoga Instructor",
        image: "https://tecdn.b-cdn.net/img/Photos/Avatars/img%20(2).jpg",
        text: "The quality of these products is unmatched. They have become an essential part of my daily routine.",
        rating: 4,
      },
      {
        name: "Anjali Verma",
        role: "Nutritionist",
        image: "https://tecdn.b-cdn.net/img/Photos/Avatars/img%20(1).jpg",
        text: "As a nutritionist, I always recommend these Ayurvedic products to my clients. They are natural, effective, and safe.",
        rating: 5,
      },
    ],
  },
];

function Testimonials() {
  return (
    <>
      <hr />
      <Carousel
        loop={true}
        autoplay={true}
        autoplayDelay={3000}
        className="rounded-xl "
      >
        {testimonials.map((testimonial, index) => (
          <div key={index}>
            <div className="mx-auto text-center md:max-w-xl lg:max-w-3xl mt-11">
              <h3 className="mb-6 text-3xl font-bold text-neutral-800 dark:text-neutral-200">
                Testimonials
              </h3>
              <p className="mb-6 pb-2 md:mb-12 md:pb-0">
                {testimonial.description}
              </p>
            </div>

            <div className="grid gap-6 text-center md:grid-cols-3 lg:gap-12 mb-5">
              {testimonial.reviews.map((review, idx) => (
                <div key={idx} className="mb-12 md:mb-0">
                  <div className="mb-6 flex justify-center">
                    <img
                      src={review.image}
                      alt={review.name}
                      className="w-32 rounded-full shadow-lg dark:shadow-black/30"
                    />
                  </div>
                  <h5 className="mb-4 text-xl font-semibold">{review.name}</h5>
                  <h6 className="mb-4 font-semibold text-primary dark:text-primary-500">
                    {review.role}
                  </h6>
                  <p className="mb-4">
                    <RiDoubleQuotesL className="inline-block h-5 w-5 mr-2 align-top text-green-500" />
                    {review.text}
                    <RiDoubleQuotesR className="inline-block h-5 w-5 ml-2 align-top text-green-500" />
                  </p>
                  <ul className="mb-5 flex items-center justify-center text-xl text-green-500">
                    {Array.from({ length: 5 }, (_, starIdx) => (
                      <li key={starIdx}>
                        {starIdx < review.rating ? (
                          <AiFillStar />
                        ) : (
                          <AiOutlineStar />
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        ))}
      </Carousel>
      <hr className="pt-5 pb-5" />
    </>
  );
}

export default Testimonials;
