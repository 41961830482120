import { AiOutlineSave } from "react-icons/ai";
import { HiOutlineSave } from "react-icons/hi";
import {
  Button,
  Input,
  Textarea,
  Typography,
  Card,
  CardBody,
  CardFooter,
} from "@material-tailwind/react";
import SideBarNav from "../../UserProfile/SideBarNav";
import { useState } from "react";
import { Link } from "react-router-dom";

const AdminAddOrders = () => {
  const [customerName, setCustomerName] = useState("");
  const [customerLastName, setCustomerLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [country, setCountry] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [orderNotice, setOrderNotice] = useState("");
  const [searchProducts, setSearchProducts] = useState("");
  const [quantity, setQuantity] = useState("");

  return (
    <div className="h-auto border-t border-gray-300 flex bg-gray-50">
      <div className="w-64 fixed h-full">
        <SideBarNav />
      </div>
      <div className="w-full px-5 ml-64">
        <Card className="mb-6">
          <CardBody className="flex justify-between items-center">
            <Typography variant="h3" className="font-bold">
              Add new order
            </Typography>
            <div className="flex gap-2">
              <Button
                variant="outlined"
                color="gray"
                className="flex items-center gap-2"
              >
                <AiOutlineSave className="text-xl" />
                Save draft
              </Button>
              <Link to="/products/add-product">
                <Button
                  variant="filled"
                  color="blue"
                  className="flex items-center gap-2"
                >
                  <HiOutlineSave className="text-xl" />
                  Publish product
                </Button>
              </Link>
            </div>
          </CardBody>
        </Card>

        {/* Add Product section here  */}
        <div className="pb-8  grid grid-cols-2 gap-x-10 max-xl:grid-cols-1 max-xl:gap-y-10">
          {/* left div */}
          <Card>
            <CardBody>
              <Typography
                variant="h4"
                className="dark:text-whiteSecondary text-blackPrimary"
              >
                Order information
              </Typography>

              <div className="mt-4 flex flex-col gap-5">
                <Input
                  label={
                    customerName ? "Customer name" : "Enter a customer name"
                  }
                  value={customerName}
                  onChange={(e) => setCustomerName(e.target.value)}
                />
                <Input
                  label={
                    customerLastName
                      ? "Customer lastname"
                      : "Enter a customer lastname"
                  }
                  value={customerLastName}
                  onChange={(e) => setCustomerLastName(e.target.value)}
                />
                <Input
                  label={
                    companyName
                      ? "Company name (optional)"
                      : "Enter a company name"
                  }
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                />
                <Input
                  label={country ? "Country" : "Enter a country"}
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                />
                <Input
                  label={
                    street
                      ? "Street and house number"
                      : "Enter a street and house number"
                  }
                  value={street}
                  onChange={(e) => setStreet(e.target.value)}
                />
                <Input
                  label={city ? "City" : "Enter a city"}
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
                <Input
                  label={zipCode ? "Zip code" : "Enter a zip code"}
                  value={zipCode}
                  onChange={(e) => setZipCode(e.target.value)}
                />
                <Input
                  label={phoneNumber ? "Phone number" : "Enter a phone number"}
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
                <Input
                  label={
                    emailAddress ? "Email address" : "Enter an email address"
                  }
                  value={emailAddress}
                  onChange={(e) => setEmailAddress(e.target.value)}
                />
                <Textarea
                  label={orderNotice ? "Order notice" : "Enter an order notice"}
                  value={orderNotice}
                  onChange={(e) => setOrderNotice(e.target.value)}
                  className="peer focus:outline-none focus:ring-0"
                />
              </div>
            </CardBody>
          </Card>

          {/* right div */}
          <Card>
            <CardBody>
              <Typography
                variant="h4"
                className="dark:text-whiteSecondary text-blackPrimary"
              >
                Products in order
              </Typography>

              <div className="mt-4 flex flex-col gap-5">
                <Input
                  label={searchProducts ? "Search products" : "Search products"}
                  value={searchProducts}
                  onChange={(e) => setSearchProducts(e.target.value)}
                />
                <Input
                  label={quantity ? "Quantity" : "Enter a quantity"}
                  type="number"
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                  className="peer focus:outline-none focus:ring-0"
                />
                <Button variant="filled" color="blue" className="w-full">
                  Add product
                </Button>
              </div>
            </CardBody>
            <CardFooter className="mt-5">
              <Typography
                variant="h4"
                className="dark:text-whiteSecondary text-blackPrimary"
              >
                Total
              </Typography>
              <div className="mt-4 flex flex-col gap-5">
                <div className="flex justify-between items-center">
                  <Typography className="dark:text-whiteSecondary text-blackPrimary">
                    Total products
                  </Typography>
                  <Typography className="dark:text-whiteSecondary text-blackPrimary">
                    0
                  </Typography>
                </div>
                <div className="flex justify-between items-center">
                  <Typography className="dark:text-whiteSecondary text-blackPrimary">
                    Total price
                  </Typography>
                  <Typography className="dark:text-whiteSecondary text-blackPrimary">
                    0
                  </Typography>
                </div>
              </div>
            </CardFooter>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default AdminAddOrders;
