import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 20,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  header: {
    fontSize: 24,
    marginBottom: 20,
  },
  subHeader: {
    fontSize: 18,
    marginBottom: 10,
  },
  text: {
    fontSize: 14,
    marginBottom: 5,
  },
  boldText: {
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 5,
  },
  product: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  productImage: {
    width: 50,
    height: 50,
    marginRight: 10,
  },
  summary: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 10,
  },
});

const InvoicePDF = ({
  orderDetail,
  productDataDetail,
  billingAddress,
  sums,
}) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.header}>Invoice</Text>
        <View style={styles.section}>
          <Text style={styles.subHeader}>Order Summary</Text>
          <Text style={styles.text}>
            Tracking number: {orderDetail[0].trackingId}
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.subHeader}>Products</Text>
          {productDataDetail?.map((product) => (
            <View key={product.id} style={styles.product}>
              <Image style={styles.productImage} src={product.imageSrc} />
              <View>
                <Text style={styles.text}>{product.name}</Text>
                <Text style={styles.text}>{product.type}</Text>
              </View>
              <Text style={styles.text}>INR {product.price}</Text>
              <Text style={styles.text}>Quantity {product.quantity}</Text>
            </View>
          ))}
        </View>
        <View style={styles.section}>
          <Text style={styles.subHeader}>Billing Address</Text>
          <Text style={styles.text}>
            {billingAddress[0].fname} {billingAddress[0].lname}
          </Text>
          <Text style={styles.text}>{orderDetail[0].billingAddress}</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.subHeader}>Payment Information</Text>
          {orderDetail[0].paymentMethod === "cash" ? (
            <Text style={styles.text}>Cash on delivery</Text>
          ) : (
            <View>
              <Text style={styles.text}>Ending with 4242</Text>
              <Text style={styles.text}>Expires 12 / 21</Text>
            </View>
          )}
        </View>
        <View style={styles.section}>
          <Text style={styles.subHeader}>Summary</Text>
          <View style={styles.summary}>
            <Text style={styles.text}>Subtotal</Text>
            <Text style={styles.text}>INR {sums.totalSubtotal}</Text>
          </View>
          <View style={styles.summary}>
            <Text style={styles.text}>Shipping</Text>
            <Text style={styles.text}>INR {sums.totalShippingCost}</Text>
          </View>
          <View style={styles.summary}>
            <Text style={styles.text}>Taxes</Text>
            <Text style={styles.text}>INR {sums.totalTax}</Text>
          </View>
          <View style={[styles.summary, { fontWeight: "bold", marginTop: 20 }]}>
            <Text style={styles.boldText}>Total</Text>
            <Text style={styles.boldText}>INR {sums.totalTotal}</Text>
          </View>
        </View>
      </View>
    </Page>
  </Document>
);

export default InvoicePDF;
