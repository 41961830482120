import { HiOutlineSave } from "react-icons/hi";
import {
  Button,
  Input,
  Textarea,
  Select,
  Option,
  Card,
  CardBody,
  Typography,
} from "@material-tailwind/react";
import { AiOutlineSave } from "react-icons/ai";
import { Link } from "react-router-dom";
import SideBarNav from "../../UserProfile/SideBarNav";
import ImageUpload from "../Products/ImageUpload";
import { useState } from "react";

const AdminAddCategories = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [slug, setSlug] = useState("");

  return (
    <div className="h-auto border-t border-blackSecondary border-1 flex dark:bg-blackPrimary bg-whiteSecondary">
      <SideBarNav />
      <div className="w-full px-5">
        <Card className="mb-6">
          <CardBody className="flex justify-between items-center">
            <Typography variant="h3" className="font-bold">
              Add new category
            </Typography>
            <div className="flex gap-2">
              <Button
                variant="outlined"
                color="gray"
                className="flex items-center gap-2"
              >
                <AiOutlineSave className="text-xl" />
                Save draft
              </Button>
              <Link to="/products/add-product">
                <Button
                  variant="filled"
                  color="blue"
                  className="flex items-center gap-2"
                >
                  <HiOutlineSave className="text-xl" />
                  Publish category
                </Button>
              </Link>
            </div>
          </CardBody>
        </Card>

        {/* Add Category section here  */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {/* left div */}
          <div>
            <Typography
              variant="h4"
              className="dark:text-whiteSecondary text-blackPrimary px-6"
            >
              Basic information
            </Typography>

            <div className="mt-4 flex flex-col space-y-4">
              <Card>
                <CardBody>
                  <Input
                    label={title ? "Title" : "Enter a category title"}
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className="peer focus:outline-none focus:ring-0"
                  />
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <Textarea
                    label={
                      description
                        ? "Category Description"
                        : "Enter a category description"
                    }
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="peer focus:outline-none focus:ring-0"
                    rows={4}
                  />
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <Input
                    label={slug ? "Category Slug" : "Enter a category slug"}
                    type="text"
                    value={slug}
                    onChange={(e) => setSlug(e.target.value)}
                    className="peer focus:outline-none focus:ring-0"
                  />
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <Select label="Parent category">
                    <Option value="1">Category 1</Option>
                    <Option value="2">Category 2</Option>
                    <Option value="3">Category 3</Option>
                  </Select>
                </CardBody>
              </Card>
            </div>
          </div>

          {/* right div */}
          <div>
            <Typography
              variant="h4"
              className="dark:text-whiteSecondary text-blackPrimary"
            >
              Category image
            </Typography>

            <Card>
              <CardBody>
                {/* Assuming ImageUpload is a custom component */}
                <ImageUpload />
              </CardBody>
            </Card>
            <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
              <div>
                <img
                  class="object-cover object-center w-full h-40 max-w-full rounded-lg"
                  src="https://images.unsplash.com/photo-1499696010180-025ef6e1a8f9?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=1470&amp;q=80"
                  alt="gallery-photo"
                />
              </div>
              <div>
                <img
                  class="object-cover object-center w-full h-40 max-w-full rounded-lg"
                  src="https://images.unsplash.com/photo-1432462770865-65b70566d673?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=1950&amp;q=80"
                  alt="gallery-photo"
                />
              </div>
              <div>
                <img
                  class="object-cover object-center w-full h-40 max-w-full rounded-lg"
                  src="https://images.unsplash.com/photo-1497436072909-60f360e1d4b1?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=2560&amp;q=80"
                  alt="gallery-photo"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminAddCategories;
