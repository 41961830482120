import { Input } from "@material-tailwind/react";
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function Newsletter() {
  const [email, setEmail] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubscribe = () => {
    setLoading(true);
    axios
      .post(`${apiUrl}/auth/subscribe`, { subscribeEmail: email })
      .then((res) => {
        if (res.data.success) {
          alert("Thanks for subscribe! We'll keep in touch with you.");
          navigate("/");
        }
      })
      .catch((err) => {
        console.log("Error:", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div className="mt-10 max-w-xl lg:max-w-lg">
        <h2 className="text-3xl font-bold tracking-tight text-Black sm:text-2xl">
          Subscribe to our newsletter
        </h2>
        <p className="mt-4 text-base leading-8 text-gray-500">
          Stay updated with the latest Ayurvedic products, health tips, and
          exclusive offers. Join our community and embrace a healthier
          lifestyle.
        </p>
        <div className="mt-6 flex max-w-md gap-x-4">
          <label htmlFor="email-address" className="sr-only">
            Email address
          </label>
          <Input label="Email" value={email} onChange={handleEmailChange} />
          <button
            type="submit"
            className="flex-none rounded-md bg-green-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-500"
            onClick={handleSubscribe}
            disabled={loading}
          >
            {loading ? "Subscribing..." : "Subscribe"}
          </button>
        </div>
      </div>
    </>
  );
}

export default Newsletter;
