import {
  Button,
  IconButton,
  Typography,
  Card,
  CardBody,
  CardFooter,
} from "@material-tailwind/react";
import { HiOutlinePlus, HiOutlineChevronRight } from "react-icons/hi";
import { AiOutlineExport } from "react-icons/ai";
import SideBarNav from "../../UserProfile/SideBarNav";

import { PencilIcon } from "@heroicons/react/24/solid";
import { EyeIcon, TrashIcon } from "@heroicons/react/24/outline";
import { CardHeader, Chip, Avatar, Tooltip } from "@material-tailwind/react";
import { Link } from "react-router-dom";

const AdminOrders = () => {
  return (
    <div className="h-auto border-t dark:border-blackSecondary border-blackSecondary border-1 flex dark:bg-blackPrimary bg-whiteSecondary">
      <div className="w-64 fixed h-full">
        <SideBarNav />
      </div>
      <div className="dark:bg-blackPrimary bg-whiteSecondary w-full ml-64">
        <Card className="dark:bg-blackPrimary bg-whiteSecondary">
          <CardBody>
            <div className="px-4 sm:px-6 lg:px-8 flex justify-between items-center max-sm:flex-col max-sm:gap-5">
              <div className="flex flex-col gap-3">
                <Typography
                  variant="h3"
                  className="text-3xl font-bold leading-7 dark:text-whiteSecondary text-blackPrimary"
                >
                  All Orders
                </Typography>
                <Typography
                  variant="paragraph"
                  className="dark:text-whiteSecondary text-blackPrimary text-base font-normal flex items-center"
                >
                  <span>Dashboard</span>
                  <HiOutlineChevronRight className="text-lg mx-2" />
                  <span>All orders</span>
                </Typography>
              </div>
              <div className="flex gap-x-2 max-[370px]:flex-col max-[370px]:gap-2 max-[370px]:items-center">
                <Button
                  variant="outlined"
                  color="gray"
                  className="flex items-center gap-x-2"
                >
                  <AiOutlineExport className="text-base" />
                  Export
                </Button>
                <Link to="/admin-add-orders">
                  <Button
                    variant="filled"
                    color="blue"
                    className="flex items-center gap-x-2"
                  >
                    <HiOutlinePlus className="text-white" />
                    Add an order
                  </Button>
                </Link>
              </div>
            </div>

            <OrderTable />
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default AdminOrders;

const TABLE_HEAD = ["Customer", "Status", "Total", "Date", "Actions"];

const TABLE_ROWS = [
  {
    img: "https://docs.material-tailwind.com/img/logos/logo-spotify.svg",
    name: "Spotify",
    status: "Processing",
    total: "$1,000",
    date: "12/12/2021",
    editIcon: PencilIcon,
    viewIcon: EyeIcon,
    deleteIcon: TrashIcon,
  },
  {
    img: "https://docs.material-tailwind.com/img/logos/logo-amazon.svg",
    name: "Amazon",
    status: "On Hold",
    total: "$1,000",
    date: "12/12/2021",
    editIcon: PencilIcon,
    viewIcon: EyeIcon,
    deleteIcon: TrashIcon,
  },
  {
    img: "https://docs.material-tailwind.com/img/logos/logo-pinterest.svg",
    name: "Pinterest",
    status: "Cancelled",
    total: "$1,000",
    date: "12/12/2021",
    editIcon: PencilIcon,
    viewIcon: EyeIcon,
    deleteIcon: TrashIcon,
  },
  {
    img: "https://docs.material-tailwind.com/img/logos/logo-google.svg",
    name: "Google",
    status: "Completed",
    total: "$1,000",
    date: "12/12/2021",
    editIcon: PencilIcon,
    viewIcon: EyeIcon,
    deleteIcon: TrashIcon,
  },
  {
    img: "https://docs.material-tailwind.com/img/logos/logo-netflix.svg",
    name: "netflix",
    status: "Processing",
    total: "$1,000",
    date: "12/12/2021",
    editIcon: PencilIcon,
    viewIcon: EyeIcon,
    deleteIcon: TrashIcon,
  },
];

const getStatusColor = (status) => {
  switch (status) {
    case "Processing":
      return "blue";
    case "On Hold":
      return "amber";
    case "Cancelled":
      return "red";
    case "Completed":
      return "green";
    default:
      return "gray";
  }
};

export const OrderTable = () => {
  return (
    <Card className="h-full w-full">
      <CardHeader
        floated={false}
        shadow={false}
        className="rounded-none"
      ></CardHeader>
      <CardBody className="overflow-visible px-0 relative z-0">
        <table className="w-full min-w-max table-auto text-center">
          <thead>
            <tr>
              {TABLE_HEAD.map((head) => (
                <th
                  key={head}
                  className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal leading-none opacity-70"
                  >
                    {head === "Customer" ? (
                      <div className="text-left pl-2">Customer</div>
                    ) : (
                      head
                    )}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {TABLE_ROWS.map(
              (
                {
                  img,
                  name,
                  status,
                  total,
                  date,
                  editIcon: EditIcon,
                  viewIcon: ViewIcon,
                  deleteIcon: DeleteIcon,
                },
                index
              ) => {
                const isLast = index === TABLE_ROWS.length - 1;
                const classes = isLast
                  ? "p-4"
                  : "p-4 border-b border-blue-gray-50";

                return (
                  <tr key={name}>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <Avatar
                          src={img}
                          alt={name}
                          size="md"
                          className="border border-blue-gray-50 bg-blue-gray-50/50 object-contain p-1"
                        />
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-bold"
                        >
                          {name}
                        </Typography>
                      </div>
                    </td>
                    <td className={classes}>
                      <Chip
                        color={getStatusColor(status)}
                        value={status}
                        className="font-normal"
                      />
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {total}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {date}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <div className="flex justify-center items-center gap-3">
                        <Tooltip content="Edit">
                          <IconButton variant="text">
                            <EditIcon className="h-4 w-4" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip content="View">
                          <IconButton variant="text">
                            <ViewIcon className="h-4 w-4" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip content="Delete">
                          <IconButton variant="text">
                            <DeleteIcon className="h-4 w-4" />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </td>
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      </CardBody>
      <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
        <Button variant="outlined" size="sm">
          Previous
        </Button>
        <div className="flex items-center gap-2">
          <IconButton variant="outlined" size="sm">
            1
          </IconButton>
          <IconButton variant="text" size="sm">
            2
          </IconButton>
          <IconButton variant="text" size="sm">
            3
          </IconButton>
          <IconButton variant="text" size="sm">
            ...
          </IconButton>
          <IconButton variant="text" size="sm">
            8
          </IconButton>
          <IconButton variant="text" size="sm">
            9
          </IconButton>
          <IconButton variant="text" size="sm">
            10
          </IconButton>
        </div>
        <Button variant="outlined" size="sm">
          Next
        </Button>
      </CardFooter>
    </Card>
  );
};
