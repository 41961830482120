// import {
//   Button,
//   Input,
//   Textarea,
//   Select,
//   Option,
//   Card,
//   CardBody,
//   Typography,
// } from "@material-tailwind/react";
// import { HiOutlineSave } from "react-icons/hi";
// import { Link } from "react-router-dom";
// import { AiOutlineSave } from "react-icons/ai";
// import SideBarNav from "../../UserProfile/SideBarNav";
// import ImageUpload from "../Products/ImageUpload";
// import { useState } from "react";
// // import { useDispatch, useSelector } from "react-redux";
// // import { addProduct } from "./adminProductsSlice";

// const AdminAddUsers = () => {
//   const [name, setName] = useState("");
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [dateOfJoining, setDateOfJoining] = useState("");
//   const [role, setRole] = useState("");
//   const [isDraft, setIsDraft] = useState(false);

//   // const dispatch = useDispatch();
//   // const { loading, error } = useSelector((state) => state.products);

//   const handleSubmit = (isDraft) => {
//     // const productData = {
//     //   name,
//     //   email,
//     //   password,
//     //   dateOfJoining,
//     //   status: isDraft ? "draft" : "published",
//     // };
//     // dispatch(addProduct(productData));
//   };

//   return (
//     <div className="h-auto border-t border-gray-300 flex bg-gray-50">
//       <SideBarNav />
//       <div className="w-full px-5">
//         <Card className="mb-6">
//           <CardBody className="flex justify-between items-center">
//             <Typography variant="h3" className="font-bold">
//               Add new user
//             </Typography>
//             <div className="flex gap-2">
//               <Button
//                 variant="outlined"
//                 color="gray"
//                 className="flex items-center gap-2"
//                 onClick={() => handleSubmit(true)}
//                 disabled
//               >
//                 <AiOutlineSave className="text-xl" />
//                 {/* {loading ? "Saving..." : "Save draft"} */}
//                 Save draft
//               </Button>
//               <Link to="/products/add-product">
//                 <Button
//                   variant="filled"
//                   color="blue"
//                   className="flex items-center gap-2"
//                   onClick={() => handleSubmit(false)}
//                   // disabled={loading}
//                 >
//                   <HiOutlineSave className="text-xl" />
//                   {/* {loading ? "Publishing..." : "Publish user"} */}
//                   Publish user
//                 </Button>
//               </Link>
//             </div>
//           </CardBody>
//         </Card>

//         <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
//           <div>
//             <Card className="mb-6">
//               <CardBody>
//                 <Typography variant="h4" className="font-bold mb-4">
//                   User information
//                 </Typography>
//                 <div className="flex flex-col gap-4">
//                   <Input
//                     label="Name"
//                     value={name}
//                     onChange={(e) => setName(e.target.value)}
//                   />
//                   <Input
//                     label="Email"
//                     value={email}
//                     onChange={(e) => setEmail(e.target.value)}
//                   />
//                   <Input
//                     label="Password"
//                     value={password}
//                     onChange={(e) => setPassword(e.target.value)}
//                   />
//                   <Input
//                     type="date"
//                     label="Date of Joining"
//                     value={dateOfJoining}
//                     onChange={(e) => setDateOfJoining(e.target.value)}
//                   />
//                   <Select
//                     label="Select a role"
//                     value={role}
//                     onChange={(value) => setRole(value)}
//                   >
//                     <Option value="admin">Admin</Option>
//                     <Option value="user">User</Option>
//                   </Select>
//                 </div>
//               </CardBody>
//             </Card>
//           </div>

//           <div>
//             <Card className="mb-6">
//               <CardBody>
//                 <Typography variant="h4" className="font-bold mb-4">
//                   User images
//                 </Typography>
//                 <ImageUpload />
//               </CardBody>
//             </Card>
//             <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
//               <div>
//                 <img
//                   class="object-cover object-center w-full h-40 max-w-full rounded-lg"
//                   src="https://images.unsplash.com/photo-1499696010180-025ef6e1a8f9?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=1470&amp;q=80"
//                   alt="gallery-photo"
//                 />
//               </div>
//               <div>
//                 <img
//                   class="object-cover object-center w-full h-40 max-w-full rounded-lg"
//                   src="https://images.unsplash.com/photo-1432462770865-65b70566d673?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=1950&amp;q=80"
//                   alt="gallery-photo"
//                 />
//               </div>
//               <div>
//                 <img
//                   class="object-cover object-center w-full h-40 max-w-full rounded-lg"
//                   src="https://images.unsplash.com/photo-1497436072909-60f360e1d4b1?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=2560&amp;q=80"
//                   alt="gallery-photo"
//                 />
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AdminAddUsers;

import {
  Button,
  Input,
  Select,
  Option,
  Card,
  CardBody,
  Typography,
} from "@material-tailwind/react";
import { HiOutlineSave } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineSave } from "react-icons/ai";
import SideBarNav from "../../UserProfile/SideBarNav";
import ImageUpload from "../Products/ImageUpload";
import { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addUserAsync } from "../../Counter/userDetailsSlice";

const AdminAddUsers = () => {
  const [fname, setFname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [dateOfJoining, setDateOfJoining] = useState("");
  const [role, setRole] = useState("");
  const [imageSrc, setImageSrc] = useState("");
  const [phone, setPhone] = useState("");

  const apiUrl = process.env.REACT_APP_API_URL;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error } = useSelector((state) => state.userDetails);

  const handleSubmit = useCallback(
    (isDraft) => {
      if (!fname || !email || !password || !dateOfJoining || !role || !phone) {
        alert("Please fill out all required fields.");
        return;
      }

      const userData = {
        fname,
        email,
        password,
        dateOfJoining,
        role,
        imageSrc,
        phone,
        status: isDraft ? "draft" : "published",
      };
      dispatch(addUserAsync(userData));
      navigate("/admin-users");
    },
    [fname, email, password, dateOfJoining, role, imageSrc, phone, dispatch]
  );

  const handleImageUpload = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch(`${apiUrl}/auth/upload`, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Image upload failed");
      }

      const data = await response.json();
      setImageSrc(data.imageUrl);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  return (
    <div className="h-auto border-t border-gray-300 flex bg-gray-50">
      <div className="w-64 fixed h-full">
        <SideBarNav />
      </div>
      <div className="w-full px-5 ml-64">
        <Card className="mb-6">
          <CardBody className="flex justify-between items-center">
            <Typography variant="h4" className="font-bold">
              Add new user
            </Typography>
            <div className="flex gap-2">
              <Button
                variant="outlined"
                color="gray"
                className="flex items-center gap-2"
                onClick={() => handleSubmit(true)}
                disabled
              >
                <AiOutlineSave className="text-xl" />
                {loading ? "Saving..." : "Save draft"}
              </Button>
              <Link>
                <Button
                  variant="filled"
                  color="blue"
                  className="flex items-center gap-2"
                  onClick={() => handleSubmit(false)}
                  disabled={loading}
                >
                  <HiOutlineSave className="text-xl" />
                  {loading ? "Publishing..." : "Publish user"}
                </Button>
              </Link>
            </div>
          </CardBody>
        </Card>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div>
            <Card className="mb-6 h-90">
              <CardBody>
                <Typography variant="h5" className="font-bold mb-4">
                  User information
                </Typography>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                  <Input
                    label="Name"
                    value={fname}
                    onChange={(e) => setFname(e.target.value)}
                  />
                  <Select
                    label="Select a role"
                    value={role}
                    onChange={(value) => setRole(value)}
                  >
                    <Option value="admin">Admin</Option>
                    <Option value="user">User</Option>
                  </Select>
                  <Input
                    label="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <Input
                    type="password"
                    label="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="peer focus:outline-none focus:ring-0"
                  />
                  <Input
                    type="date"
                    label="Date of Joining"
                    value={dateOfJoining}
                    onChange={(e) => setDateOfJoining(e.target.value)}
                    className="peer focus:outline-none focus:ring-0"
                  />
                  <Input
                    type="tel"
                    label="Mobile Number"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    className="peer focus:outline-none focus:ring-0"
                  />
                </div>
              </CardBody>
            </Card>
          </div>

          <div>
            <Card className="mb-6 h-60">
              <CardBody>
                <Typography variant="h5" className="font-bold mb-4">
                  User images
                </Typography>
                <ImageUpload onUpload={handleImageUpload} className="h-48" />
                {imageSrc && (
                  <div className="mt-4">
                    <img
                      className="object-cover object-center w-full h-40 max-w-full rounded-lg"
                      src={imageSrc}
                      alt="Uploaded user"
                    />
                  </div>
                )}
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminAddUsers;
