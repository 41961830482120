import React, { useState } from "react";
import {
  Card,
  Typography,
  List,
  ListItem,
  ListItemPrefix,
  ListItemSuffix,
  Chip,
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import {
  PresentationChartBarIcon,
  ShoppingBagIcon,
  UserCircleIcon,
  Cog6ToothIcon,
  PowerIcon,
  ClipboardDocumentListIcon,
} from "@heroicons/react/24/solid";
import { HeartIcon } from "@heroicons/react/24/outline";
import { ChevronRightIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import { Link, useNavigate } from "react-router-dom";
import { selectWishlistProduct } from "../Cart/wishlistSlice";
import { useDispatch, useSelector } from "react-redux";
import { clearCart } from "../Cart/cartProductSlice";
import { setLogout } from "../Counter/userDetailsSlice";
import axios from "axios";

export default function SideBarNav() {
  const [open, setOpen] = useState(0);
  const wishlistItems = useSelector(selectWishlistProduct);
  const apiUrl = process.env.REACT_APP_API_URL;
  const userRole = useSelector((state) => state?.userDetails?.userDetail.role);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  const handleLogout = () => {
    localStorage.clear();
    axios
      .post(`${apiUrl}/auth/logout`)
      .then((res) => {
        if (res.data.success) {
          alert("Logout Successfully.");
          dispatch(clearCart());
          dispatch(setLogout());
          navigate("/");
        } else {
          alert("Error");
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <Card className="h-[calc(100vh-2rem)] w-full max-w-[20rem] p-4 shadow-xl shadow-blue-gray-900/5">
      <div className="mb-2 p-4">
        <Typography variant="h5" color="blue-gray">
          Welcome Back!
        </Typography>
      </div>
      <List>
        {userRole === "admin" && (
          <Accordion
            open={open === 1}
            icon={
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`mx-auto h-4 w-4 transition-transform ${
                  open === 1 ? "rotate-180" : ""
                }`}
              />
            }
          >
            <ListItem className="p-0" selected={open === 1}>
              <AccordionHeader
                onClick={() => handleOpen(1)}
                className="border-b-0 p-3"
              >
                <ListItemPrefix>
                  <PresentationChartBarIcon className="h-5 w-5" />
                </ListItemPrefix>
                <Typography color="blue-gray" className="mr-auto font-normal">
                  Admin Panel
                </Typography>
              </AccordionHeader>
            </ListItem>
            <AccordionBody className="py-1">
              <List className="p-0">
                <ListItem>
                  <ListItemPrefix>
                    <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                  </ListItemPrefix>
                  <Link to="/admin-products">Products</Link>
                </ListItem>
                <ListItem>
                  <ListItemPrefix>
                    <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                  </ListItemPrefix>
                  <Link to="/admin-orders">Orders</Link>
                </ListItem>
                <ListItem>
                  <ListItemPrefix>
                    <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                  </ListItemPrefix>
                  <Link to="/admin-categories">Categories</Link>
                </ListItem>
                <ListItem>
                  <ListItemPrefix>
                    <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                  </ListItemPrefix>
                  <Link to="/admin-users">Users</Link>
                </ListItem>
              </List>
            </AccordionBody>
          </Accordion>
        )}
        <ListItem>
          <ListItemPrefix>
            <ShoppingBagIcon strokeWidth={3} className="h-5 w-5" />
          </ListItemPrefix>
          <Link to="/orderHistory">Order History</Link>
        </ListItem>
        <ListItem>
          <ListItemPrefix>
            <ClipboardDocumentListIcon strokeWidth={3} className="h-5 w-5" />
          </ListItemPrefix>
          <Link to="/invoice">Invoice</Link>
        </ListItem>
        <ListItem>
          <ListItemPrefix>
            <HeartIcon className="h-5 w-5" />
          </ListItemPrefix>
          <Link to="/wishlist">Wishlist</Link>
          <ListItemSuffix>
            <Chip
              value={wishlistItems.length}
              size="sm"
              variant="ghost"
              color="blue-gray"
              className="rounded-full"
            />
          </ListItemSuffix>
        </ListItem>
        <hr className="my-2 border-blue-gray-50" />

        <ListItem>
          <ListItemPrefix>
            <UserCircleIcon className="h-5 w-5" />
          </ListItemPrefix>
          <Link to="/userprofile">Profile</Link>
        </ListItem>

        <ListItem>
          <ListItemPrefix>
            <Cog6ToothIcon className="h-5 w-5" />
          </ListItemPrefix>
          <Link to="/editprofile">Settings</Link>
        </ListItem>
        <ListItem onClick={handleLogout}>
          <ListItemPrefix>
            <PowerIcon className="h-5 w-5" />
          </ListItemPrefix>
          <Typography as="button" className="text-left w-full">
            Log Out
          </Typography>
        </ListItem>
      </List>
    </Card>
  );
}
