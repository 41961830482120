// import {
//   Button,
//   Input,
//   Textarea,
//   Select,
//   Option,
//   Card,
//   CardBody,
//   Typography,
// } from "@material-tailwind/react";
// import { HiOutlineSave } from "react-icons/hi";
// import { Link } from "react-router-dom";
// import { AiOutlineSave } from "react-icons/ai";
// import SideBarNav from "../../UserProfile/SideBarNav";
// import ImageUpload from "./ImageUpload";
// import { useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { addProduct } from "./adminProductsSlice";

// const AdminAddProducts = () => {
//   const [title, setTitle] = useState("");
//   const [description, setDescription] = useState("");
//   const [basePricing, setBasePricing] = useState("");
//   const [priceWithDiscount, setPriceWithDiscount] = useState("");
//   const [stock, setStock] = useState("");
//   const [sku, setSku] = useState("");
//   const [weight, setWeight] = useState("");
//   const [length, setLength] = useState("");
//   const [width, setWidth] = useState("");
//   const [height, setHeight] = useState("");
//   const [isDraft, setIsDraft] = useState(false);

//   const dispatch = useDispatch();
//   const { loading, error } = useSelector((state) => state.adminProducts);

//   const handleSubmit = (isDraft) => {
//     const productData = {
//       title,
//       description,
//       basePricing,
//       priceWithDiscount,
//       stock,
//       sku,
//       // weight,
//       // length,
//       // width,
//       // height,
//       status: isDraft ? "draft" : "published",
//     };
//     dispatch(addProduct(productData));
//   };

//   return (
//     <div className="h-auto border-t border-gray-300 flex bg-gray-50">
//       <SideBarNav />
//       <div className="w-full px-5">
//         <Card className="mb-6">
//           <CardBody className="flex justify-between items-center">
//             <Typography variant="h3" className="font-bold">
//               Add new product
//             </Typography>
//             <div className="flex gap-2">
//               <Button
//                 variant="outlined"
//                 color="gray"
//                 className="flex items-center gap-2"
//                 onClick={() => handleSubmit(true)}
//                 disabled={loading}
//               >
//                 <AiOutlineSave className="text-xl" />
//                 {loading ? "Saving..." : "Save draft"}
//               </Button>
//               <Link to="/products/add-product">
//                 <Button
//                   variant="filled"
//                   color="blue"
//                   className="flex items-center gap-2"
//                   onClick={() => handleSubmit(false)}
//                   disabled={loading}
//                 >
//                   <HiOutlineSave className="text-xl" />
//                   {loading ? "Publishing..." : "Publish product"}
//                 </Button>
//               </Link>
//             </div>
//           </CardBody>
//         </Card>

//         <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
//           <div>
//             <Card className="mb-6">
//               <CardBody>
//                 <Typography variant="h4" className="font-bold mb-4">
//                   Basic information
//                 </Typography>
//                 <div className="flex flex-col gap-4">
//                   <Input
//                     label={title ? "Title" : "Enter a product title"}
//                     value={title}
//                     onChange={(e) => setTitle(e.target.value)}
//                   />
//                   <Textarea
//                     label={
//                       description
//                         ? "Description"
//                         : "Enter a product description"
//                     }
//                     value={description}
//                     onChange={(e) => setDescription(e.target.value)}
//                     className="peer focus:outline-none focus:ring-0"
//                     rows={4}
//                   />
//                   <Select label="Category">
//                     <Option value="1">Ayurvedic Syrups & Capsules</Option>
//                     <Option value="2">Ayurvedic Oils & Ras</Option>
//                     <Option value="3">Powders & Churans</Option>
//                     <Option value="4">Herbal Face & Skin Care</Option>
//                     <Option value="5">Herbal Hair Care</Option>
//                     <Option value="6">Soaps & Gels</Option>
//                   </Select>
//                 </div>
//               </CardBody>
//             </Card>

//             <Card className="mb-6">
//               <CardBody>
//                 <Typography variant="h4" className="font-bold mb-4">
//                   Pricing & Inventory
//                 </Typography>
//                 <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
//                   <Input
//                     label={
//                       basePricing
//                         ? "Base pricing"
//                         : "Enter a product base pricing"
//                     }
//                     type="number"
//                     value={basePricing}
//                     onChange={(e) => setBasePricing(e.target.value)}
//                     className="peer focus:outline-none focus:ring-0"
//                   />
//                   <Input
//                     label={
//                       priceWithDiscount
//                         ? "Price with discount"
//                         : "Enter a price with discount"
//                     }
//                     type="number"
//                     value={priceWithDiscount}
//                     onChange={(e) => setPriceWithDiscount(e.target.value)}
//                     className="peer focus:outline-none focus:ring-0"
//                   />
//                   <Input
//                     label={stock ? "Stock" : "Enter a product stock"}
//                     type="number"
//                     value={stock}
//                     onChange={(e) => setStock(e.target.value)}
//                     className="peer focus:outline-none focus:ring-0"
//                   />
//                   <Input
//                     label={sku ? "SKU" : "Enter a product SKU"}
//                     value={sku}
//                     onChange={(e) => setSku(e.target.value)}
//                   />
//                 </div>
//                 <Select label="Stock status">
//                   <Option value="1">In stock</Option>
//                   <Option value="2">Out of stock</Option>
//                 </Select>
//               </CardBody>
//             </Card>

//             {/* <Card className="mb-6">
//               <CardBody>
//                 <Typography variant="h4" className="font-bold mb-4">
//                   Delivery
//                 </Typography>
//                 <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
//                   <Input
//                     label={weight ? "Weight (kg)" : "Enter a product weight"}
//                     type="number"
//                     value={weight}
//                     onChange={(e) => setWeight(e.target.value)}
//                     className="peer focus:outline-none focus:ring-0"
//                   />
//                   <Input
//                     label={length ? "Length (cm)" : "Enter a product length"}
//                     type="number"
//                     value={length}
//                     onChange={(e) => setLength(e.target.value)}
//                     className="peer focus:outline-none focus:ring-0"
//                   />
//                   <Input
//                     label={width ? "Width (cm)" : "Enter a product width"}
//                     type="number"
//                     value={width}
//                     onChange={(e) => setWidth(e.target.value)}
//                     className="peer focus:outline-none focus:ring-0"
//                   />
//                   <Input
//                     label={height ? "Height (cm)" : "Enter a product height"}
//                     type="number"
//                     value={height}
//                     onChange={(e) => setHeight(e.target.value)}
//                     className="peer focus:outline-none focus:ring-0"
//                   />
//                 </div>
//               </CardBody>
//             </Card> */}
//           </div>

//           <div>
//             <Card className="mb-6">
//               <CardBody>
//                 <Typography variant="h4" className="font-bold mb-4">
//                   Product images
//                 </Typography>
//                 <ImageUpload />
//               </CardBody>
//             </Card>
//             <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
//               <div>
//                 <img
//                   class="object-cover object-center w-full h-40 max-w-full rounded-lg"
//                   src="https://images.unsplash.com/photo-1499696010180-025ef6e1a8f9?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=1470&amp;q=80"
//                   alt="gallery-photo"
//                 />
//               </div>
//               <div>
//                 <img
//                   class="object-cover object-center w-full h-40 max-w-full rounded-lg"
//                   src="https://images.unsplash.com/photo-1432462770865-65b70566d673?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=1950&amp;q=80"
//                   alt="gallery-photo"
//                 />
//               </div>
//               <div>
//                 <img
//                   class="object-cover object-center w-full h-40 max-w-full rounded-lg"
//                   src="https://images.unsplash.com/photo-1497436072909-60f360e1d4b1?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=2560&amp;q=80"
//                   alt="gallery-photo"
//                 />
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AdminAddProducts;

// import {
//   Button,
//   Input,
//   Textarea,
//   Select,
//   Option,
//   Card,
//   CardBody,
//   Typography,
// } from "@material-tailwind/react";
// import { AiOutlineSave } from "react-icons/ai";
// import { Link } from "react-router-dom";
// import SideBarNav from "../../UserProfile/SideBarNav";
// import ImageUpload from "./ImageUpload";
// import { useState, useCallback } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { addProduct } from "./adminProductsSlice";

// const AdminAddProducts = () => {
//   const [title, setTitle] = useState("");
//   const [description, setDescription] = useState("");
//   const [basePricing, setBasePricing] = useState("");
//   const [priceWithDiscount, setPriceWithDiscount] = useState("");
//   const [stock, setStock] = useState("");
//   // const [sku, setSku] = useState("");
//   const [category, setCategory] = useState("");
//   const [weight, setWeight] = useState("");
//   const [length, setLength] = useState("");
//   const [width, setWidth] = useState("");
//   const [height, setHeight] = useState("");
//   const [isDraft, setIsDraft] = useState(false);

//   const dispatch = useDispatch();
//   const { loading, error } = useSelector((state) => state.adminProducts);

//   const handleSubmit = useCallback(
//     (isDraft) => {
//       if (
//         !title ||
//         !description ||
//         !basePricing ||
//         !priceWithDiscount ||
//         !stock ||
//         // !sku ||
//         !category
//       ) {
//         alert("Please fill out all required fields.");
//         return;
//       }

//       const productData = {
//         title,
//         description,
//         basePricing,
//         priceWithDiscount,
//         stock,
//         // sku,
//         category,
//         status: isDraft ? "draft" : "published",
//       };
//       dispatch(addProduct(productData));
//     },
//     [
//       title,
//       description,
//       basePricing,
//       priceWithDiscount,
//       stock,
//       // sku,
//       category,
//       dispatch,
//     ]
//   );

//   return (
//     <div className="h-auto border-t border-gray-300 flex bg-gray-50">
//       <SideBarNav />
//       <div className="w-full px-5">
//         <Card className="mb-6">
//           <CardBody className="flex justify-between items-center">
//             <Typography variant="h3" className="font-bold">
//               Add new product
//             </Typography>
//             <div className="flex gap-2">
//               <Button
//                 variant="outlined"
//                 color="gray"
//                 className="flex items-center gap-2"
//                 onClick={() => handleSubmit(true)}
//                 disabled={loading}
//                 aria-label="Save draft"
//               >
//                 <AiOutlineSave className="text-xl" />
//                 {loading ? "Saving..." : "Save draft"}
//               </Button>
//               <Link to="/products/add-product">
//                 <Button
//                   variant="filled"
//                   color="blue"
//                   className="flex items-center gap-2"
//                   onClick={() => handleSubmit(false)}
//                   disabled={loading}
//                   aria-label="Publish product"
//                 >
//                   <AiOutlineSave className="text-xl" />
//                   {loading ? "Publishing..." : "Publish product"}
//                 </Button>
//               </Link>
//             </div>
//           </CardBody>
//         </Card>

//         <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
//           <div>
//             <Card className="mb-6">
//               <CardBody>
//                 <Typography variant="h4" className="font-bold mb-4">
//                   Basic information
//                 </Typography>
//                 <div className="flex flex-col gap-4">
//                   <Input
//                     label={title ? "Title" : "Enter a product title"}
//                     value={title}
//                     onChange={(e) => setTitle(e.target.value)}
//                     aria-label="Product title"
//                   />
//                   <Textarea
//                     label={
//                       description
//                         ? "Description"
//                         : "Enter a product description"
//                     }
//                     value={description}
//                     onChange={(e) => setDescription(e.target.value)}
//                     className="peer focus:outline-none focus:ring-0"
//                     rows={4}
//                     aria-label="Product description"
//                   />
//                   <Select
//                     label="Category"
//                     value={category}
//                     onChange={(e) => setCategory(e.target.value)}
//                     aria-label="Product category"
//                   >
//                     <Option value="1">Ayurvedic Remedies</Option>
//                     <Option value="2">Herbal Personal Care</Option>
//                     <Option value="3">Health & Wellness Supplements</Option>
//                   </Select>
//                 </div>
//               </CardBody>
//             </Card>

//             <Card className="mb-6">
//               <CardBody>
//                 <Typography variant="h4" className="font-bold mb-4">
//                   Pricing & Inventory
//                 </Typography>
//                 <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
//                   <Input
//                     label={
//                       basePricing
//                         ? "Base pricing"
//                         : "Enter a product base pricing"
//                     }
//                     type="number"
//                     value={basePricing}
//                     onChange={(e) => setBasePricing(e.target.value)}
//                     className="peer focus:outline-none focus:ring-0"
//                     aria-label="Base pricing"
//                   />
//                   <Input
//                     label={
//                       priceWithDiscount
//                         ? "Price with discount"
//                         : "Enter a price with discount"
//                     }
//                     type="number"
//                     value={priceWithDiscount}
//                     onChange={(e) => setPriceWithDiscount(e.target.value)}
//                     className="peer focus:outline-none focus:ring-0"
//                     aria-label="Price with discount"
//                   />
//                   <Input
//                     label={stock ? "Stock" : "Enter a product stock"}
//                     type="number"
//                     value={stock}
//                     onChange={(e) => setStock(e.target.value)}
//                     className="peer focus:outline-none focus:ring-0"
//                     aria-label="Stock"
//                   />
//                   {/* <Input
//                     label={sku ? "SKU" : "Enter a product SKU"}
//                     value={sku}
//                     onChange={(e) => setSku(e.target.value)}
//                     aria-label="SKU"
//                   /> */}
//                   <Select label="Stock status" aria-label="Stock status">
//                     <Option value="1">In stock</Option>
//                     <Option value="2">Out of stock</Option>
//                   </Select>
//                 </div>
//               </CardBody>
//             </Card>
//           </div>

//           <div>
//             <Card className="mb-6">
//               <CardBody>
//                 <Typography variant="h4" className="font-bold mb-4">
//                   Product images
//                 </Typography>
//                 <ImageUpload />
//               </CardBody>
//             </Card>
//             <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
//               <div>
//                 <img
//                   className="object-cover object-center w-full h-40 max-w-full rounded-lg"
//                   src="https://images.unsplash.com/photo-1499696010180-025ef6e1a8f9?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=1470&amp;q=80"
//                   alt="gallery-photo"
//                 />
//               </div>
//               <div>
//                 <img
//                   className="object-cover object-center w-full h-40 max-w-full rounded-lg"
//                   src="https://images.unsplash.com/photo-1432462770865-65b70566d673?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=1950&amp;q=80"
//                   alt="gallery-photo"
//                 />
//               </div>
//               <div>
//                 <img
//                   className="object-cover object-center w-full h-40 max-w-full rounded-lg"
//                   src="https://images.unsplash.com/photo-1497436072909-60f360e1d4b1?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=2560&amp;q=80"
//                   alt="gallery-photo"
//                 />
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AdminAddProducts;

// import {
//   Button,
//   Input,
//   Textarea,
//   Select,
//   Option,
//   Card,
//   CardBody,
//   Typography,
// } from "@material-tailwind/react";
// import { AiOutlineSave } from "react-icons/ai";
// import { Link } from "react-router-dom";
// import SideBarNav from "../../UserProfile/SideBarNav";
// import ImageUpload from "./ImageUpload";
// import { useState, useCallback } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { addProduct } from "./adminProductsSlice";

// const AdminAddProducts = () => {
//   const [name, setName] = useState("");
//   const [imageSrc, setImageSrc] = useState("");
//   const [price, setPrice] = useState("");
//   const [rating, setRating] = useState("");
//   const [type, setType] = useState("");
//   const [quantity, setQuantity] = useState("");
//   const [category_id, setCategoryId] = useState("");
//   const [stock, setStock] = useState("");

//   const dispatch = useDispatch();
//   const { loading, error } = useSelector((state) => state.adminProducts);

//   const handleSubmit = useCallback(
//     (isDraft) => {
//       if (
//         !name ||
//         !imageSrc ||
//         !price ||
//         !rating ||
//         !type ||
//         !quantity ||
//         !category_id ||
//         !stock
//       ) {
//         alert("Please fill out all required fields.");
//         return;
//       }

//       const productData = {
//         name,
//         imageSrc,
//         price,
//         rating,
//         type,
//         quantity,
//         category_id,
//         stock,
//         status: isDraft ? "draft" : "published",
//       };
//       dispatch(addProduct(productData));
//     },
//     [name, imageSrc, price, rating, type, quantity, category_id, dispatch]
//   );

//   return (
//     <div className="h-auto border-t border-gray-300 flex bg-gray-50">
//       <SideBarNav />
//       <div className="w-full px-5">
//         <Card className="mb-6">
//           <CardBody className="flex justify-between items-center">
//             <Typography variant="h3" className="font-bold">
//               Add new product
//             </Typography>
//             <div className="flex gap-2">
//               <Button
//                 variant="outlined"
//                 color="gray"
//                 className="flex items-center gap-2"
//                 onClick={() => handleSubmit(true)}
//                 disabled={loading}
//                 aria-label="Save draft"
//               >
//                 <AiOutlineSave className="text-xl" />
//                 {loading ? "Saving..." : "Save draft"}
//               </Button>
//               <Link to="/products/add-product">
//                 <Button
//                   variant="filled"
//                   color="blue"
//                   className="flex items-center gap-2"
//                   onClick={() => handleSubmit(false)}
//                   disabled={loading}
//                   aria-label="Publish product"
//                 >
//                   <AiOutlineSave className="text-xl" />
//                   {loading ? "Publishing..." : "Publish product"}
//                 </Button>
//               </Link>
//             </div>
//           </CardBody>
//         </Card>

//         <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
//           <div>
//             <Card className="mb-6">
//               <CardBody>
//                 <Typography variant="h4" className="font-bold mb-4">
//                   Basic information
//                 </Typography>
//                 <div className="flex flex-col gap-4">
//                   <Input
//                     label={name ? "Name" : "Enter a product name"}
//                     value={name}
//                     onChange={(e) => setName(e.target.value)}
//                     aria-label="Product name"
//                   />
//                   {/* <Textarea
//                     label={imageSrc ? "Image URL" : "Enter a product image URL"}
//                     value={imageSrc}
//                     onChange={(e) => setImageSrc(e.target.value)}
//                     className="peer focus:outline-none focus:ring-0"
//                     rows={4}
//                     aria-label="Product image URL"
//                   /> */}
//                   <div className="flex gap-4">
//                     <Select
//                       label="Category"
//                       value={category_id}
//                       onChange={(value) => setCategoryId(value)}
//                       aria-label="Product category"
//                     >
//                       <Option value="1">Ayurvedic Remedies</Option>
//                       <Option value="2">Herbal Personal Care</Option>
//                       <Option value="3">Health & Wellness Supplements</Option>
//                     </Select>
//                     <Select
//                       label="Product Type"
//                       value={type}
//                       onChange={(value) => setType(value)}
//                       aria-label="Product type"
//                     >
//                       <Option value="capsules">Capsules</Option>
//                       <Option value="face-cream">Face Cream</Option>
//                       <Option value="hair-oil">Hair-Oil</Option>
//                       <Option value="supplements">Supplements</Option>
//                       <Option value="syrup">Syrup</Option>
//                       <Option value="tonic">Tonic</Option>
//                     </Select>
//                   </div>
//                 </div>
//               </CardBody>
//             </Card>

//             <Card className="mb-6">
//               <CardBody>
//                 <Typography variant="h4" className="font-bold mb-4">
//                   Pricing & Inventory
//                 </Typography>
//                 <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
//                   <Input
//                     label={price ? "Price" : "Enter a product price"}
//                     type="number"
//                     value={price}
//                     onChange={(e) => setPrice(e.target.value)}
//                     className="peer focus:outline-none focus:ring-0"
//                     aria-label="Price"
//                   />
//                   <Input
//                     label={quantity ? "Quantity" : "Enter a product quantity"}
//                     type="number"
//                     value={quantity}
//                     onChange={(e) => setQuantity(e.target.value)}
//                     className="peer focus:outline-none focus:ring-0"
//                     aria-label="Quantity"
//                   />
//                   <Select
//                     label="Stock status"
//                     value={type}
//                     onChange={(value) => setStock(value)}
//                     aria-label="Stock status"
//                   >
//                     <Option value="1">In stock</Option>
//                     <Option value="2">Out of stock</Option>
//                   </Select>
//                   <Input
//                     label={rating ? "Rating" : "Enter a product rating"}
//                     type="number"
//                     value={rating}
//                     onChange={(e) => setRating(e.target.value)}
//                     className="peer focus:outline-none focus:ring-0"
//                     aria-label="Rating"
//                   />
//                 </div>
//               </CardBody>
//             </Card>
//           </div>

//           <div>
//             <Card className="mb-6">
//               <CardBody>
//                 <Typography variant="h4" className="font-bold mb-4">
//                   Product images
//                 </Typography>
//                 <ImageUpload />
//               </CardBody>
//             </Card>
//             <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
//               <div>
//                 <img
//                   className="object-cover object-center w-full h-40 max-w-full rounded-lg"
//                   src="https://images.unsplash.com/photo-1499696010180-025ef6e1a8f9?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=1470&amp;q=80"
//                   alt="gallery-photo"
//                 />
//               </div>
//               <div>
//                 <img
//                   className="object-cover object-center w-full h-40 max-w-full rounded-lg"
//                   src="https://images.unsplash.com/photo-1432462770865-65b70566d673?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=1950&amp;q=80"
//                   alt="gallery-photo"
//                 />
//               </div>
//               <div>
//                 <img
//                   className="object-cover object-center w-full h-40 max-w-full rounded-lg"
//                   src="https://images.unsplash.com/photo-1497436072909-60f360e1d4b1?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=2560&amp;q=80"
//                   alt="gallery-photo"
//                 />
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AdminAddProducts;

import {
  Button,
  Input,
  Textarea,
  Select,
  Option,
  Card,
  CardBody,
  Typography,
} from "@material-tailwind/react";
import { AiOutlineSave } from "react-icons/ai";
import { Link } from "react-router-dom";
import SideBarNav from "../../UserProfile/SideBarNav";
import ImageUpload from "./ImageUpload";
import { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createProductAsync } from "../../Product/productSlice";

const AdminAddProducts = () => {
  const [name, setName] = useState("");
  const [imageSrc, setImageSrc] = useState("");
  const [price, setPrice] = useState("");
  const [rating, setRating] = useState("");
  const [type, setType] = useState("");
  const [quantity, setQuantity] = useState("");
  const [category_id, setCategoryId] = useState("");
  const [stock, setStock] = useState("");

  const apiUrl = process.env.REACT_APP_API_URL;

  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.product);

  const handleSubmit = useCallback(
    (isDraft) => {
      if (
        !name ||
        !imageSrc ||
        !price ||
        !rating ||
        !type ||
        !quantity ||
        !category_id ||
        !stock
      ) {
        alert("Please fill out all required fields.");
        return;
      }

      const productData = {
        name,
        imageSrc,
        price,
        rating,
        type,
        quantity,
        category_id,
        stock,
        status: isDraft ? "draft" : "published",
      };
      dispatch(createProductAsync(productData));
    },
    [
      name,
      imageSrc,
      price,
      rating,
      type,
      quantity,
      category_id,
      stock,
      dispatch,
    ]
  );

  const handleImageUpload = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    console.log("file", file);
    console.log("formData", formData);

    try {
      const response = await fetch(`${apiUrl}/auth/upload`, {
        method: "POST",
        body: formData,
      });
      console.log("response", response);

      if (!response.ok) {
        throw new Error("Image upload failed");
      }

      const data = await response.json();
      setImageSrc(data.imageUrl);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  return (
    <div className="h-auto border-t border-gray-300 flex bg-gray-50">
      <div className="w-64 fixed h-full">
        <SideBarNav />
      </div>
      <div className="w-full px-5 ml-64">
        <Card className="mb-6">
          <CardBody className="flex justify-between items-center">
            <Typography variant="h4" className="font-bold">
              Add new product
            </Typography>
            <div className="flex gap-2">
              <Button
                variant="outlined"
                color="gray"
                className="flex items-center gap-2"
                onClick={() => handleSubmit(true)}
                disabled
                aria-label="Save draft"
              >
                <AiOutlineSave className="text-xl" />
                {loading ? "Saving..." : "Save draft"}
              </Button>

              <Button
                variant="filled"
                color="blue"
                className="flex items-center gap-2"
                onClick={() => handleSubmit(false)}
                disabled={loading}
                aria-label="Publish product"
              >
                <AiOutlineSave className="text-xl" />
                {loading ? "Publishing..." : "Publish product"}
              </Button>
            </div>
          </CardBody>
        </Card>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div>
            <Card className="mb-6">
              <CardBody>
                <Typography variant="h5" className="font-bold mb-4">
                  Basic information
                </Typography>
                <div className="flex flex-col gap-4">
                  <Input
                    label={name ? "Name" : "Enter a product name"}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    aria-label="Product name"
                  />
                  <div className="flex gap-4">
                    <Select
                      label="Category"
                      value={category_id}
                      onChange={(value) => setCategoryId(value)}
                      aria-label="Product category"
                    >
                      <Option value="1">Ayurvedic Remedies</Option>
                      <Option value="2">Herbal Personal Care</Option>
                      <Option value="3">Health & Wellness Supplements</Option>
                    </Select>
                    <Select
                      label="Product Type"
                      value={type}
                      onChange={(value) => setType(value)}
                      aria-label="Product type"
                    >
                      <Option value="capsules">Capsules</Option>
                      <Option value="face-cream">Face Cream</Option>
                      <Option value="hair-oil">Hair-Oil</Option>
                      <Option value="supplements">Supplements</Option>
                      <Option value="syrup">Syrup</Option>
                      <Option value="tonic">Tonic</Option>
                    </Select>
                  </div>
                </div>
              </CardBody>
            </Card>

            <Card className="mb-6">
              <CardBody>
                <Typography variant="h5" className="font-bold mb-4">
                  Pricing & Inventory
                </Typography>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                  <Input
                    label={price ? "Price" : "Enter a product price"}
                    type="number"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    className="peer focus:outline-none focus:ring-0"
                    aria-label="Price"
                  />
                  <Input
                    label={quantity ? "Quantity" : "Enter a product quantity"}
                    type="number"
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                    className="peer focus:outline-none focus:ring-0"
                    aria-label="Quantity"
                  />
                  <Select
                    label="Stock status"
                    value={stock}
                    onChange={(value) => setStock(value)}
                    aria-label="Stock status"
                  >
                    <Option value="1">In stock</Option>
                    <Option value="2">Out of stock</Option>
                  </Select>
                  <Input
                    label={rating ? "Rating" : "Enter a product rating"}
                    type="number"
                    value={rating}
                    onChange={(e) => setRating(e.target.value)}
                    className="peer focus:outline-none focus:ring-0"
                    aria-label="Rating"
                  />
                </div>
              </CardBody>
            </Card>
          </div>

          <div>
            <Card className="mb-6">
              <CardBody>
                <Typography variant="h5" className="font-bold mb-4">
                  Product images
                </Typography>
                <ImageUpload onUpload={handleImageUpload} />
                {imageSrc && (
                  <div className="mt-4">
                    <img
                      className="object-cover object-center w-full h-40 max-w-full rounded-lg"
                      src={imageSrc}
                      alt="Uploaded product"
                    />
                  </div>
                )}
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminAddProducts;
