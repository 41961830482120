// import React, { useEffect, useState, Fragment } from "react";
// import axios from "axios";
// import { Link, useNavigate } from "react-router-dom";
// import { Dialog, Transition } from "@headlessui/react";
// import {
//   ClockIcon,
//   HeartIcon,
//   HomeIcon,
//   QuestionMarkCircleIcon,
//   ShieldCheckIcon,
// } from "@heroicons/react/24/outline";
// import { CheckCircleIcon } from "@heroicons/react/24/solid";
// import { HiOutlineDocumentReport } from "react-icons/hi";
// import { FiShoppingBag } from "react-icons/fi";
// import { MdOutlineAccountBalanceWallet } from "react-icons/md";
// import { FaRegAddressCard } from "react-icons/fa";
// import { MdLogout } from "react-icons/md";
// import { CiSettings } from "react-icons/ci";
// import { clearCart } from "../Cart/cartProductSlice";
// import { selectUserDetails, setLogout } from "../Counter/userDetailsSlice";
// import { useDispatch, useSelector } from "react-redux";
// import defaultImage from "../../images/default.png";
// import { FaFileInvoice } from "react-icons/fa";

// import {
//   Card,
//   Typography,
//   List,
//   ListItem,
//   ListItemPrefix,
//   ListItemSuffix,
//   Chip,
//   Accordion,
//   AccordionHeader,
//   AccordionBody,
// } from "@material-tailwind/react";
// import {
//   PresentationChartBarIcon,
//   ShoppingBagIcon,
//   UserCircleIcon,
//   Cog6ToothIcon,
//   InboxIcon,
//   PowerIcon,
// } from "@heroicons/react/24/solid";
// import { ChevronRightIcon, ChevronDownIcon } from "@heroicons/react/24/outline";

// function UserProfile() {
//   const [name, setName] = useState("");

//   const navigate = useNavigate();

//   const userDetail = useSelector(selectUserDetails);

//   // const clientId = '992772560544-93mcb3ublmql2p0b9navenei9s40fqvq.apps.googleusercontent.com';

//   axios.defaults.withCredentials = true;

//   useEffect(() => {
//     axios
//       .get("http://localhost:5001")
//       .then((res) => {
//         if (res.data.valid) {
//           setName(res.data.fname);
//         } else {
//           navigate("/login");
//         }
//       })
//       .catch((err) => console.log(err));
//   }, []);

//   const cards = [
//     {
//       name: "Account balance",
//       href: "#",
//       icon: MdOutlineAccountBalanceWallet,
//       amount: "$30,659.45",
//     },
//   ];
//   const transactions = [
//     {
//       id: 1,
//       name: "Payment to Molly Sanders",
//       href: "#",
//       amount: "$20,000",
//       currency: "USD",
//       status: "success",
//       date: "July 11, 2020",
//       datetime: "2020-07-11",
//     },
//   ];
//   const statusStyles = {
//     success: "bg-green-100 text-green-800",
//     processing: "bg-yellow-100 text-yellow-800",
//     failed: "bg-gray-100 text-gray-800",
//   };

//   function classNames(...classes) {
//     return classes.filter(Boolean).join(" ");
//   }

//   return (
//     <>
//       <div className=" min-h-0">
//         <SideBarNav />
//         <div className="lg:pl-80 flex flex-col flex-1">
//           <main className="flex-1 pb-8">
//             {/* Page header */}
//             <div className="bg-white shadow">
//               <div className="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
//                 <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
//                   <div className="flex-1 min-w-0">
//                     {/* Profile */}
//                     <div className="flex items-center">
//                       <img
//                         className="hidden h-8 w-16 rounded-full sm:block"
//                         src={defaultImage}
//                         alt=""
//                       />
//                       <div>
//                         <div className="flex items-center">
//                           <img
//                             className="h-8 w-16 rounded-full sm:hidden"
//                             src={defaultImage}
//                             alt=""
//                           />
//                           <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">
//                             Hello, {name}
//                           </h1>
//                         </div>
//                         <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
//                           <dt className="sr-only">Company</dt>
//                           <dd className="flex items-center text-sm text-gray-500 font-medium capitalize sm:mr-6">
//                             <FaRegAddressCard
//                               className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
//                               aria-hidden="true"
//                             />
//                             {userDetail.address}
//                           </dd>
//                           <dt className="sr-only">Account status</dt>
//                           <dd className="mt-3 flex items-center text-sm text-gray-500 font-medium sm:mr-6 sm:mt-0 capitalize">
//                             <CheckCircleIcon
//                               className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
//                               aria-hidden="true"
//                             />
//                             Verified account
//                           </dd>
//                         </dl>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </main>
//         </div>
//       </div>
//     </>
//   );
// }

// export default UserProfile;

// export function SideBarNav() {
//   const [open, setOpen] = React.useState(0);

//   const handleOpen = (value) => {
//     setOpen(open === value ? 0 : value);
//   };

//   return (
//     <Card className="h-[calc(100vh-2rem)] w-full max-w-[20rem] p-4 shadow-xl shadow-blue-gray-900/5">
//       <div className="mb-2 p-4">
//         <Typography variant="h5" color="blue-gray">
//           Welcome Back!
//         </Typography>
//       </div>
//       <List>
//         <Accordion
//           open={open === 1}
//           icon={
//             <ChevronDownIcon
//               strokeWidth={2.5}
//               className={`mx-auto h-4 w-4 transition-transform ${
//                 open === 1 ? "rotate-180" : ""
//               }`}
//             />
//           }
//         >
//           <ListItem className="p-0" selected={open === 1}>
//             <AccordionHeader
//               onClick={() => handleOpen(1)}
//               className="border-b-0 p-3"
//             >
//               <ListItemPrefix>
//                 <PresentationChartBarIcon className="h-5 w-5" />
//               </ListItemPrefix>
//               <Typography color="blue-gray" className="mr-auto font-normal">
//                 Admin Panel
//               </Typography>
//             </AccordionHeader>
//           </ListItem>
//           <AccordionBody className="py-1">
//             <List className="p-0">
//               <ListItem>
//                 <ListItemPrefix>
//                   <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
//                 </ListItemPrefix>
//                 Products
//               </ListItem>
//               <ListItem>
//                 <ListItemPrefix>
//                   <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
//                 </ListItemPrefix>
//                 Categories
//               </ListItem>
//               <ListItem>
//                 <ListItemPrefix>
//                   <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
//                 </ListItemPrefix>
//                 Orders
//               </ListItem>
//             </List>
//           </AccordionBody>
//         </Accordion>
//         <Accordion
//           open={open === 2}
//           icon={
//             <ChevronDownIcon
//               strokeWidth={2.5}
//               className={`mx-auto h-4 w-4 transition-transform ${
//                 open === 2 ? "rotate-180" : ""
//               }`}
//             />
//           }
//         >
//           <ListItem className="p-0" selected={open === 2}>
//             <AccordionHeader
//               onClick={() => handleOpen(2)}
//               className="border-b-0 p-3"
//             >
//               <ListItemPrefix>
//                 <ShoppingBagIcon className="h-5 w-5" />
//               </ListItemPrefix>
//               <Typography color="blue-gray" className="mr-auto font-normal">
//                 E-Commerce
//               </Typography>
//             </AccordionHeader>
//           </ListItem>
//           <AccordionBody className="py-1">
//             <List className="p-0">
//               <ListItem>
//                 <ListItemPrefix>
//                   <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
//                 </ListItemPrefix>
//                 <Link to="/orderHistory">Order History</Link>
//               </ListItem>
//               <ListItem>
//                 <ListItemPrefix>
//                   <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
//                 </ListItemPrefix>
//                 <Link to="/orderSummary">Order Summary</Link>
//               </ListItem>
//               <ListItem>
//                 <ListItemPrefix>
//                   <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
//                 </ListItemPrefix>
//                 <Link to="/invoice">Invoice</Link>
//               </ListItem>
//             </List>
//           </AccordionBody>
//         </Accordion>
//         <hr className="my-2 border-blue-gray-50" />

//         <ListItem>
//           <ListItemPrefix>
//             <UserCircleIcon className="h-5 w-5" />
//           </ListItemPrefix>
//           <Link to="/profile">Profile</Link>
//         </ListItem>
//         <ListItem>
//           <ListItemPrefix>
//             <HeartIcon className="h-5 w-5" />
//           </ListItemPrefix>
//           <Link to="/wishlist">Wishlist</Link>
//           <ListItemSuffix>
//             <Chip
//               value="14"
//               size="sm"
//               variant="ghost"
//               color="blue-gray"
//               className="rounded-full"
//             />
//           </ListItemSuffix>
//         </ListItem>
//         <ListItem>
//           <ListItemPrefix>
//             <Cog6ToothIcon className="h-5 w-5" />
//           </ListItemPrefix>
//           <Link to="/editprofile">Settings</Link>
//         </ListItem>
//         <ListItem>
//           <ListItemPrefix>
//             <PowerIcon className="h-5 w-5" />
//           </ListItemPrefix>
//           <Link to="/logout">Log Out</Link>
//         </ListItem>
//       </List>
//     </Card>
//   );
// }

import React, { useEffect, useState, Fragment } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { FiShoppingBag } from "react-icons/fi";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { FaRegAddressCard } from "react-icons/fa";
import { MdLogout } from "react-icons/md";
import { selectUserDetails, setLogout } from "../Counter/userDetailsSlice";
import { useDispatch, useSelector } from "react-redux";
import defaultImage from "../../images/default.png";
import SideBarNav from "./SideBarNav";

function UserProfile() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [name, setName] = useState("");

  const navigate = useNavigate();

  const userDetail = useSelector(selectUserDetails);

  axios.defaults.withCredentials = true;

  useEffect(() => {
    axios
      .get(`${apiUrl}`)
      .then((res) => {
        if (res.data.valid) {
          setName(res.data.fname);
        } else {
          navigate("/login");
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const cards = [
    {
      name: "Account balance",
      href: "#",
      icon: MdOutlineAccountBalanceWallet,
      amount: "$30,659.45",
    },
  ];
  const transactions = [
    {
      id: 1,
      name: "Payment to Molly Sanders",
      href: "#",
      amount: "$20,000",
      currency: "USD",
      status: "success",
      date: "July 11, 2020",
      datetime: "2020-07-11",
    },
  ];
  const statusStyles = {
    success: "bg-green-100 text-green-800",
    processing: "bg-yellow-100 text-yellow-800",
    failed: "bg-gray-100 text-gray-800",
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <div className="flex min-h-screen">
      <div className="w-64 fixed h-full">
        <SideBarNav />
      </div>
      <div className="flex-1 flex flex-col ml-64">
        <main className="flex-1 pb-8">
          {/* Page header */}
          <div className="bg-white shadow">
            <div className="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
              <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
                <div className="flex-1 min-w-0">
                  {/* Profile */}
                  <div className="flex items-center">
                    <img
                      className="hidden h-8 w-16 rounded-full sm:block"
                      src={defaultImage}
                      alt=""
                    />
                    <div>
                      <div className="flex items-center">
                        <img
                          className="h-8 w-16 rounded-full sm:hidden"
                          src={defaultImage}
                          alt=""
                        />
                        <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">
                          Hello, {name}
                        </h1>
                      </div>
                      <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                        <dt className="sr-only">Company</dt>
                        <dd className="flex items-center text-sm text-gray-500 font-medium capitalize sm:mr-6">
                          <FaRegAddressCard
                            className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          {userDetail.address}
                        </dd>
                        <dt className="sr-only">Account status</dt>
                        <dd className="mt-3 flex items-center text-sm text-gray-500 font-medium sm:mr-6 sm:mt-0 capitalize">
                          <CheckCircleIcon
                            className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                            aria-hidden="true"
                          />
                          Verified account
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default UserProfile;
